import React, { useEffect } from 'react';

import { Page, DashboardItem } from '@components';
import { useSelector } from 'react-redux';
import { authSelector, fetchUserApprovalGroup } from '@redux/slices/auth';
import { Col, Dropdown, Row, Typography } from 'antd';
import meta from '../meta.json';
import { useDispatch } from 'react-redux';
import {
    approvalSelector,
    draftsSelector,
    fetchApprovals,
    fetchDrafts,
    resetOrderStates,
} from '@redux/slices/order';
import { fetchScheduled, scheduledValues } from '@redux/slices/order/scheduled';
import { CustomMenu } from '@components/header/styled';
import { Text } from '@styles/components/text';
import { DashboardItemContainer } from '@components/dashboard-item/styled';
import { Link } from 'react-router-dom';
import { translate } from '@components/i18n';

const Home: React.FC = () => {
    const authState = useSelector(authSelector);
    const draftsState = useSelector(draftsSelector);
    const scheduledState = useSelector(scheduledValues);
    const approvalState = useSelector(approvalSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchDrafts());
        dispatch(resetOrderStates());
        dispatch(fetchScheduled());
        dispatch(fetchApprovals({ current: 1, pageSize: 9999 }));
        dispatch(fetchUserApprovalGroup());
    }, []);

    const topMenu = (
        <CustomMenu key="rel-menu" style={{ marginTop: 5 }}>
            {meta.sideMenus
                .find((m) => m.title === 'sideMenu.reports.self')
                ?.subMenus?.filter(
                    (m) =>
                        authState.currentUser.isAdmin ||
                        m.allowedRoles?.some((r) =>
                            authState.currentUser.roles.some((c) => c === r),
                        ),
                )
                .map((m, i) => {
                    return (
                        <CustomMenu.Item key={i}>
                            <Link
                                to={m.path}
                                key={`side-menu-${translate(
                                    m.title,
                                )}-${i}-link`}
                            >
                                <Row gutter={12}>
                                    <Col>
                                        <Text
                                            blue
                                            fontWeight={400}
                                            fontSize={15}
                                        >
                                            {translate(m.title)}
                                        </Text>
                                    </Col>
                                </Row>
                            </Link>
                        </CustomMenu.Item>
                    );
                })}
        </CustomMenu>
    );

    return (
        <Page title={translate('pages.home')}>
            <Col span={24} style={{ marginTop: '50px' }}>
                <Row justify="center">
                    <Col span={2} />
                    <Col span={18}>
                        <Row justify="start">
                            <Typography.Title
                                level={3}
                                style={{ marginBottom: 0 }}
                            >
                                {translate('home.hello')}{' '}
                                {authState.currentUser.name.split(' ')[0]}
                            </Typography.Title>
                        </Row>
                        <Col>
                            <Row justify="start">
                                <Typography.Title
                                    level={3}
                                    style={{
                                        fontWeight: 400,
                                        marginBottom: 40,
                                    }}
                                >
                                    {translate('home.welcome')}
                                </Typography.Title>
                            </Row>
                        </Col>
                        <Col>
                            <Row style={{ rowGap: 30, columnGap: 30 }} wrap>
                                {meta.menus
                                    .filter(
                                        (m) =>
                                            authState.currentUser.isAdmin ||
                                            m.allowedRoles?.some((r) =>
                                                authState.currentUser.roles.some(
                                                    (c) => c === r,
                                                ),
                                            ),
                                    )
                                    .map(({ badge, ...m }) => {
                                        return (
                                            <>
                                                {m.description !==
                                                    'home.cards.reports' && (
                                                    <DashboardItem
                                                        key={`dashboard-item-${translate(
                                                            m.title,
                                                        )}-${translate(
                                                            m.description,
                                                        )}`}
                                                        badge={
                                                            badge
                                                                ? m.description ===
                                                                  'home.cards.scheduled'
                                                                    ? scheduledState?.length
                                                                    : m.description ===
                                                                      'home.cards.approvals'
                                                                    ? approvalState
                                                                          .availableApprovals
                                                                          ?.length
                                                                    : draftsState
                                                                          .drafts
                                                                          ?.length
                                                                : null
                                                        }
                                                        {...m}
                                                        title={translate(
                                                            m.title,
                                                        )}
                                                        description={translate(
                                                            m.description,
                                                        )}
                                                    />
                                                )}
                                                {m.description ===
                                                    'home.cards.reports' && (
                                                    <Dropdown
                                                        overlay={topMenu}
                                                        trigger={['click']}
                                                        key={'rel'}
                                                        placement="topCenter"
                                                    >
                                                        <DashboardItemContainer
                                                            xs={24}
                                                            sm={24}
                                                            md={7}
                                                            lg={7}
                                                            xl={7}
                                                        >
                                                            <DashboardItem
                                                                key={`dashboard-item-${translate(
                                                                    m.title,
                                                                )}-${translate(
                                                                    m.description,
                                                                )}`}
                                                                title={translate(
                                                                    m.title,
                                                                )}
                                                                description={translate(
                                                                    m.description,
                                                                )}
                                                                icon={m.icon}
                                                                path={''}
                                                            />
                                                        </DashboardItemContainer>
                                                    </Dropdown>
                                                )}
                                            </>
                                        );
                                    })}
                            </Row>
                        </Col>
                    </Col>
                    <Col span={2} />
                </Row>
            </Col>
        </Page>
    );
};

export default Home;
