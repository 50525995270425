export interface UserRequest {
    name: string;
    photo: string;
    birthday?: Date;
    division: string;
    password?: string;
    roles?: string[];
    email: string;
    login: string;
    jdeCode: string;
    active: boolean;
    limiteUsuario?: number;
    limiteBonificacaoUsuario?: number;
    grupoAprovacaoVendaId?: number;
    grupoAprovacaoBonificacaoMarketingId?: number;
    grupoAprovacaoDescontosId?: number;
    itensEspecificos?: string;
    emailCopia?: string;
    dailyReport?: boolean;
    approvalsDailyReport?: boolean;
    gerenteLinha?: boolean;
    codigoLinha?: number;
    codigoTipoTransporte?: string;
    exibirClientesDivisao?: boolean;
    permissaoRelatorio?: string
}

export class UserRequestAPI {
    constructor(data?: UserRequest) {
        if (data) {
            this.codigoJDE = Number(data.jdeCode);
            this.divisao = data.division;
            this.email = data.email;
            this.exibirClientesDivisao = data.exibirClientesDivisao;
            this.habilitado = data.active;
            this.login = data.login;
            this.nome = data.name;
            this.senha = data.password;
            this.limiteUsuario = data.limiteUsuario;
            this.limiteBonificacaoUsuario = data.limiteBonificacaoUsuario;
            this.grupoAprovacaoVendaId = data.grupoAprovacaoVendaId;
            this.grupoAprovacaoBonificacaoMarketingId =
                data.grupoAprovacaoBonificacaoMarketingId;
            this.grupoAprovacaoDescontosId = data.grupoAprovacaoDescontosId;
            this.itensEspecificos = data.itensEspecificos;
            this.emailCopia = data.emailCopia;
            this.papeisEnum = data.roles;
            this.ignorarEmailDiarioProgramados = data.dailyReport
                ? true
                : false;
            this.ignorarEmailDiarioAprovacao = data.approvalsDailyReport
                ? true
                : false;
            this.gerenteLinha = data.gerenteLinha ? true : false;
            this.codigoLinha = data.codigoLinha;
            this.codigoTipoTransporte = data.codigoTipoTransporte;
            this.permissaoRelatorio = data.permissaoRelatorio
        }
    }

    codigoJDE: number;
    divisao: string;
    email: string;
    exibirClientesDivisao: boolean;
    habilitado: boolean;
    login: string;
    nome: string;
    senha: string;
    limiteUsuario: number;
    limiteBonificacaoUsuario: number;
    grupoAprovacaoVendaId: number;
    grupoAprovacaoBonificacaoMarketingId: number;
    grupoAprovacaoDescontosId?: number;
    emailCopia?: string;
    itensEspecificos: string;
    papeisEnum?: string[];
    ignorarEmailDiarioProgramados?: boolean;
    ignorarEmailDiarioAprovacao?: boolean;
    gerenteLinha?: boolean;
    codigoLinha?: number;
    codigoTipoTransporte?: string;
    permissaoRelatorio?: string
}
