import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios from '@axios';
import { RootState } from '@redux/reducers';
import { Paginated } from '@models/paginated';
import { toQueryParameters } from 'src/extensions/object';
import { PaginatedResponseApi } from '@models/order/items/response';
import { ordersHistoryReportColumnsTranslations } from '@utils/translation';
import {
    OrderHistoryResponse,
    OrderHistoryResponseApi,
    ReportRequest,
} from '@models/report';
import { exportReport } from '@utils/file';
import { translate } from '@components/i18n';

export const fetchOrdersHistory = createAsyncThunk<
    Paginated<OrderHistoryResponse>,
    ReportRequest
>('orders-history/fetchOrdersHistory', async (request, thunkAPI) => {
    try {
        const state = thunkAPI.getState() as RootState;
        const { ordersHistory } = state;
        const { request: oldRequest } = ordersHistory;

        thunkAPI.dispatch(updateRequestOrderHistory(request));

        const params = generateParams(request, oldRequest);

        const response = await axios.get<
            PaginatedResponseApi<OrderHistoryResponseApi>
        >(`/relatorio/pedidoshistorico?${params}`);
        const { data: dataResponse, status: statusResponse } = response;

        if (!dataResponse) {
            return {
                items: [],
                current: 0,
                total: 0,
                pageSize: 0,
            };
        } else {
            const result = dataResponse.content.map((u) =>
                Object.assign(new OrderHistoryResponseApi(), u),
            );

            if (statusResponse === 200) {
                return {
                    items: result.map((u) => u.toOrderHistoryResponse()),
                    current: request.current,
                    total: response.data.totalElements,
                    pageSize: request.pageSize,
                };
            } else {
                return thunkAPI.rejectWithValue('');
            }
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const exportOrdersHistory = createAsyncThunk(
    'orders-history/exportOrdersHistory',
    async (request: ReportRequest, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;
            const { approvedOrders } = state;
            const { request: oldRequest } = approvedOrders;

            const params = generateParams(request, oldRequest);

            const response = await axios.get(
                `/relatorio/pedidoshistorico/export?${params}`,
                { responseType: 'blob' },
            );
            exportReport(
                response.data,
                `${translate('general.reportOrderHistory')}.xlsx`,
            );
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;

            return thunkAPI.rejectWithValue(message);
        }
    },
);

const generateParams = (request: ReportRequest, oldRequest: ReportRequest) => {
    const { filterValue, filterBy } = oldRequest;

    if (filterValue != request.filterValue || filterBy != request.filterBy)
        request.current = 1;

    let parameters: any = {
        status: request.status,
        page: request.current - 1,
        size: request.pageSize,
        sort: request.field
            ? `${ordersHistoryReportColumnsTranslations[request.field] || ''},${
                  request.order === 'ascend' ? 'asc' : 'desc'
              }`
            : '',
    };

    parameters =
        request.filterValue && request.filterBy && request.comparisonType
            ? {
                  ...parameters,
                  filterValue: request.filterValue,
                  filterType: request.filterBy?.toUpperCase(),
                  comparisonType: request.comparisonType?.toUpperCase(),
              }
            : parameters;

    return toQueryParameters(parameters);
};

interface IOrderHistorySelectorState {
    orderHistory: OrderHistoryResponse;
    ordersHistory: OrderHistoryResponse[];
    request: ReportRequest;
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage: string;
}

const initialState: IOrderHistorySelectorState = {
    orderHistory: undefined,
    ordersHistory: [],
    request: {
        filterBy: '',
        filterValue: '',
        comparisonType: '',
        status: '',
        pageSize: 10,
        current: 1,
        total: 0,
    },
    isFetching: true,
    isSuccess: false,
    isError: false,
    errorMessage: '',
};

const ordersHistorySlice = createSlice({
    name: 'ordersHistorySlice',
    initialState,
    reducers: {
        ordersHistory: (state, action: PayloadAction<OrderHistoryResponse>) => {
            state.orderHistory = action.payload;

            return state;
        },
        updateRequestOrderHistory: (
            state,
            action: PayloadAction<ReportRequest>,
        ) => {
            state.request = action.payload;

            return state;
        },
        resetOrderHistoryState: (state) => {
            state = { ...initialState };
            return state;
        },
    },
    extraReducers: {
        [fetchOrdersHistory.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Paginated<OrderHistoryResponse>>,
        ) => {
            state.ordersHistory = [...payload.items];
            state.request = {
                ...state.request,
                total: payload.total,
                pageSize: payload.pageSize,
                current: payload.current,
            };
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchOrdersHistory.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },
        [fetchOrdersHistory.pending.toString()]: (state) => {
            state.isFetching = true;
            state.isError = false;
        },
    },
});

export const orderHistorySelector = (
    state: RootState,
): IOrderHistorySelectorState => state.ordersHistory;

export const {
    ordersHistory,
    updateRequestOrderHistory,
    resetOrderHistoryState,
} = ordersHistorySlice.actions;

export default ordersHistorySlice.reducer;
