import { LANGUAGE } from '@config/env';

// without notation
export const formatCurrency = (money: number): string => {
    return money
        .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
        .replace('US$', '')
        .trim()
        .replace('$', '');
};

// with notation
export const formatMoney = (money: number | string): string => {
    if (typeof money !== 'number' && !money) {
        return '';
    }

    if (isNaN(Number(money))) {
        return '';
    }

    const number = Number(money);

    switch (LANGUAGE) {
        case 'es-AR':
            return number.toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

        case 'pt-BR':
        default:
            return number.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
    }
};

// only notation
export const getMoneyNotation = (): string => {
    switch (LANGUAGE) {
        case 'es-AR':
            return '$';

        case 'pt-BR':
        default:
            return 'R$';
    }
};
