/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import moment from 'moment';
import { EditableField } from '@models/editable-field';
import { translate } from '@components/i18n';

export const columns = [
    {
        title: translate('columns.name'),
        dataIndex: 'nome',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.division'),
        dataIndex: 'divisao',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.startDate'),
        dataIndex: 'dataInicial',
        sorter: false,
        width: '15%',
        render: (_, record): React.ReactNode => {
            return (
                <span>{moment(record.dataInicial).format('DD/MM/YYYY')}</span>
            );
        },
    },
    {
        title: translate('columns.endDate'),
        dataIndex: 'dataFinal',
        sorter: false,
        width: '15%',
        render: (_, record): React.ReactNode => {
            return <span>{moment(record.dataFinal).format('DD/MM/YYYY')}</span>;
        },
    },
    {
        title: translate('columns.color'),
        dataIndex: 'cor',
        sorter: false,
        width: '10%',
    },
    {
        title: translate('columns.percent'),
        dataIndex: 'percentual',
        sorter: false,
        width: '10%',
        render: (text): React.ReactNode => {
            return <span>{Number(text).toFixed(2)}%</span>;
        },
    },
];

export function editableFields(): EditableField[] {
    return [
        {
            name: 'nome',
            label: translate('forms.labels.name'),
            type: 'string',
            span: 12,
        },
        {
            name: 'divisao',
            label: translate('forms.labels.division'),
            type: 'text',
            span: 12,
        },
        {
            name: 'dataInicial',
            label: translate('columns.startDate'),
            type: 'date',
            format: 'DD/MM/YYYY',
            span: 12,
        },
        {
            name: 'dataFinal',
            label: translate('columns.endDate'),
            type: 'date',
            format: 'DD/MM/YYYY',
            span: 12,
            rules: [
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (
                            value &&
                            moment(getFieldValue('dataInicial')).isAfter(
                                moment(value),
                                'day',
                            )
                        ) {
                            return Promise.reject(
                                new Error(
                                    translate(
                                        'forms.rules.endDateCannotBeBeforeStart',
                                    ),
                                ),
                            );
                        }
                        return Promise.resolve();
                    },
                }),
            ],
        },
        {
            name: 'cor',
            label: translate('forms.labels.color'),
            type: 'color',
            span: 12,
        },
        {
            name: 'percentual',
            label: translate('forms.labels.percent'),
            type: 'number',
            span: 12,
        },
    ];
}
