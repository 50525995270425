/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import moment from 'moment';
import { EditableField } from '@models/editable-field';
import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';

export const columns = [
    {
        title: translate('columns.numberItem'),
        dataIndex: 'numeroItem',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.startDate'),
        dataIndex: 'dataInicial',
        sorter: false,
        width: '15%',
        render: (_, record): React.ReactNode => {
            return (
                <span>{moment(record.dataInicial).format('DD/MM/YYYY')}</span>
            );
        },
    },
    {
        title: translate('columns.endDate'),
        dataIndex: 'dataFinal',
        sorter: false,
        width: '15%',
        render: (_, record): React.ReactNode => {
            return <span>{moment(record.dataFinal).format('DD/MM/YYYY')}</span>;
        },
    },
    {
        title: translate('columns.value'),
        dataIndex: 'valor',
        sorter: true,
        width: '10%',
        render: (_, record): React.ReactNode => {
            return <span>{formatMoney(record.valor)}</span>;
        },
    },
];

export function editableFields(): EditableField[] {
    return [
        {
            name: 'numeroItem',
            label: translate('forms.labels.numberItem'),
            type: 'text',
            span: 12,
        },
        {
            name: 'dataInicial',
            label: translate('columns.startDate'),
            type: 'date',
            format: 'DD/MM/YYYY',
            span: 12,
        },
        {
            name: 'dataFinal',
            label: translate('columns.endDate'),
            type: 'date',
            format: 'DD/MM/YYYY',
            span: 12,
            rules: [
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (
                            value &&
                            moment(getFieldValue('dataInicial')).isAfter(
                                moment(value),
                                'day',
                            )
                        ) {
                            return Promise.reject(
                                new Error(
                                    translate(
                                        'forms.rules.endDateCannotBeBeforeStart',
                                    ),
                                ),
                            );
                        }
                        return Promise.resolve();
                    },
                }),
            ],
        },
        {
            name: 'valor',
            label: translate('forms.labels.value'),
            type: 'number',
            span: 12,
        },
    ];
}
