import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@redux/reducers';
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

export interface Notification {
    type: 'warning' | 'success' | 'error';
    title: string;
    notificationKey?: string;
    message: string;
    callback?: (...args) => void;
}

interface INotificationState {
    notifications: Notification[];
}

const initialState: INotificationState = {
    notifications: [],
};

const draftsSlice = createSlice({
    name: 'notificationsSlice',
    initialState,
    reducers: {
        addNotification: (state, action: PayloadAction<Notification>) => {
            state.notifications = [...state.notifications, action.payload];
            return state;
        },
        removeNotification: (state, action: PayloadAction<Notification>) => {
            state.notifications = state.notifications.filter(
                (n) => n.title !== action.payload.title,
            );

            return state;
        },
    },
});

export const notificationsSelector = (state: RootState): Notification[] =>
    state.notification.notifications;

export const { addNotification, removeNotification } = draftsSlice.actions;

export default draftsSlice.reducer;
