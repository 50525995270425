import { translate } from '@components/i18n';
import '@extensions/number';
import { CampaignIndicator } from '@pages/order/components/styled';
import { Dot } from '@styles/components/button';
import { colors } from '@utils/constants';
import { formatMoney } from '@utils/money';
import { Row, Tooltip } from 'antd';
import { useContext } from 'react';
import { FinancialDiscountContext } from './context/context';

export const columns = [
    {
        title: translate('columns.code'),
        dataIndex: 'id',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.representer'),
        dataIndex: 'representative',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('general.orderType'),
        dataIndex: 'type',
        sorter: false,
        width: '10%',
    },
    {
        title: translate('columns.division'),
        dataIndex: 'division',
        sorter: false,
        width: '10%',
    },
    {
        title: translate('columns.pandingWith'),
        dataIndex: 'pendingWith',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('filters.clientCode'),
        dataIndex: 'customerCode',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('filters.clientName'),
        dataIndex: 'customerName',
        sorter: false,
        width: '20%',
    },
    {
        title: translate('filters.customerCity'),
        dataIndex: 'customerCity',
        sorter: false,
        width: '20%',
    },
    {
        title: translate('filters.hasBonification'),
        dataIndex: 'hasBonification',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('filters.value'),
        dataIndex: 'total',
        sorter: false,
        width: '15%',
    },
];

export const filterTypes = [
    {
        name: 'PEDIDO',
        description: translate('filters.order'),
    },
    {
        name: 'VALOR_TOTAL',
        description: translate('filters.totalAmount'),
    },
    {
        name: 'DATA',
        description: translate('filters.date'),
    },
    {
        name: 'DIVISAO',
        description: translate('filters.division'),
    },
    {
        name: 'CODIGO_CLIENTE',
        description: translate('filters.clientCode'),
    },
    {
        name: 'NOME_CLIENTE',
        description: translate('filters.clientName'),
    },
    {
        name: 'VENDEDOR',
        description: translate('columns.seller'),
    },
    {
        name: 'TIPO_PEDIDO',
        description: translate('filters.orderType'),
    },
];

export const filterComparison = [
    {
        name: 'EQ',
        description: translate('comparison.equalTo'),
    },
    {
        name: 'NEQ',
        description: translate('comparison.diferentThen'),
    },
    {
        name: 'GT',
        description: translate('comparison.biggerThen'),
    },
    {
        name: 'LT',
        description: translate('comparison.lessThen'),
    },
    {
        name: 'CONTAINS',
        description: translate('comparison.contains'),
    },
    {
        name: 'NOT_CONTAINS',
        description: translate('comparison.noContains'),
    },
    {
        name: 'BEGINS_WITH',
        description: translate('comparison.startWith'),
    },
];

export const itemsColumns = [
    {
        title: translate('columns.item'),
        dataIndex: 'description',
        width: '22%',
        render: (text, record): JSX.Element => {
            const campaigns = useContext(FinancialDiscountContext);
            const campaign = campaigns.campaigns?.find(
                (c) => c.id === record.campanhaId,
            );
            const color =
                colors[
                    campaigns.campaigns?.findIndex(
                        (c) => c.id === record.campanhaId,
                    )
                ];
            return (
                <Row wrap={false}>
                    {record.campanhaId && (
                        <Tooltip title={`${campaign?.nome}`}>
                            <CampaignIndicator
                                color={color}
                            ></CampaignIndicator>
                        </Tooltip>
                    )}
                    {text}
                </Row>
            );
        },
    },
    {
        title: translate('columns.code'),
        dataIndex: 'code',
        width: '10%',
    },
    {
        title: translate('columns.qtd'),
        dataIndex: 'quantity',
        width: '7%',
    },
    {
        title: translate('columns.presentationQtd'),
        dataIndex: 'quantityPresentation',
        width: '10%',
    },
    {
        title: translate('columns.amountQtd'),
        dataIndex: 'totalQuantity',
        width: '10%',
    },
    {
        title: translate('columns.price'),
        dataIndex: 'costModifiable',
        width: '12%',
        render: (text): JSX.Element  => {
            return (
                <Row
                    style={{
                        display: 'flex',
                        columnGap: '5px',
                        userSelect: 'none',
                    }}
                >
                    {formatMoney(text)}
                </Row>
            );
        },
    },
    {
        title: translate('columns.promo'),
        dataIndex: 'promotion',
        width: '6%',
        render: (text): JSX.Element  => {
            return (
                <span>
                    {isNaN(text) ? text : `${Number(text).toFixed(2)}%`}
                </span>
            );
        },
    },
    {
        title: translate('columns.discount'),
        dataIndex: 'discount',
        width: '8%',
        render: (text): JSX.Element  => {
            return <span>{Number(text).toFixed(2)}%</span>;
        },
    },
    {
        title: translate('columns.subTotal'),
        dataIndex: 'total',
        width: '10%',
        render: (text, record): JSX.Element  => {
            return (
                <span
                    style={{
                        display: 'flex',
                        columnGap: '5px',
                        userSelect: 'none',
                    }}
                >
                    {formatMoney(text)}
                    {record.semaforo && <Dot background={record.semaforo} />}
                </span>
            );
        },
    },
];
