import React, { memo, useCallback, useEffect, useState } from 'react';
import { Form, message, Row } from 'antd';
import { Button } from '@styles/components/button';
import { FormItem, Select, Option } from '@styles/components/form';
import { InputDate } from '@components/input';
import locale from 'antd/es/date-picker/locale/pt_BR';
import localeEs from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { OrderType } from '@models/order/order-type';
import { useDispatch, useSelector } from 'react-redux';
import {
    customerSelector,
    fetchAvailablePlatforms,
    orderTypeSelector,
    selectType,
    updateOrder,
} from '@redux/slices/order';
import { PlatformRequest } from '@models/order/platform/request';
import { useQuery } from 'react-query';
import axios from '@axios';
import { TypeResponseApi } from '@models/order/type/response';
import { isEsAr, translate } from '@components/i18n';

export const SelectTypeForm: React.FC = memo(() => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [formState, setFormState] = useState<PlatformRequest>();
    const selectTypeState = useSelector(orderTypeSelector);
    const selectCustomerState = useSelector(customerSelector);

    const goBack = (): void => history.goBack();
    const { data, refetch } = useQuery('suggestion', async () => {
        const result = await axios.get<TypeResponseApi>(
            `/filial/byusuario/cliente/${selectCustomerState.customer.code}?companhiaCodigo=${selectCustomerState.customer.company}`,
        );

        const data = Object.assign(new TypeResponseApi(), result.data);

        return data.toTypeResponse();
    });

    useEffect(() => {
        if (data && selectTypeState.availablePlatforms.length > 0) {
            form.setFieldsValue({ platform: data.id });
            setFormState({ ...formState, platform: data.id });
        } else refetch();
    }, [data, selectTypeState.availablePlatforms]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onChange = (prev: Partial<OrderType>, data: any): void => {
        setFormState(data);

        if (data.type !== formState?.type) {
            dispatch(fetchAvailablePlatforms({ type: parseInt(data.type) }));
            refetch();
        }
    };

    const isValid = useCallback(() => {
        if (!!formState?.platform && !!formState?.type) return false;
        return true;
    }, [formState]);

    const onSubmit = (): void => {
        dispatch(
            selectType({
                ...formState,
                date: formState.date ? new Date(formState.date) : undefined,
            }),
        );
        dispatch(
            updateOrder({
                orderType: {
                    ...formState,
                    date: formState.date ? new Date(formState.date) : undefined,
                },
            }),
        );
        console.debug(formState, 'type');
        if (formState.type.toString() === 'S1')
            history.push('/create-order/select-items');
        if (formState.type.toString() !== 'S1')
            history.push('/order/marketing-bonification');
    };

    useEffect(() => {
        if (
            !selectTypeState.isFetchingPlatform &&
            selectTypeState.isSuccessPlatform &&
            selectTypeState.availablePlatforms.length === 0
        )
            message.error(translate('general.filialAvailableError'), 3);
    }, [selectTypeState.isFetchingPlatform, selectTypeState.isSuccessPlatform]);

    return (
        <Form
            form={form}
            onValuesChange={onChange}
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
        >
            <FormItem label={translate('forms.labels.orderType')} name={'type'}>
                <Select
                    placeholder={translate('forms.placeholders.chooseOption')}
                    loading={selectTypeState.isFetching}
                >
                    {selectTypeState.availableTypes
                        .filter((f) => `${f.id}` !== 'V1')
                        .map((t) => (
                            <Option value={t.id}>{t.description}</Option>
                        ))}
                </Select>
            </FormItem>
            <FormItem
                label={translate('forms.labels.originPlatform')}
                name={'platform'}
            >
                <Select
                    placeholder={translate('forms.placeholders.chooseOption')}
                    loading={selectTypeState.isFetchingPlatform}
                    allowClear
                >
                    {selectTypeState.availablePlatforms.map((t) => (
                        <Option value={t.id}>
                            {t.id} - {t.description}
                        </Option>
                    ))}
                </Select>
            </FormItem>
            <Form.Item
                name={'date'}
                label={translate('forms.labels.scheduleOrder')}
                
            >
                <InputDate
                    locale={isEsAr() ? localeEs : locale}
                    format={'DD/MM/YYYY'}
                    disabledDate={(current) => {
                        return moment() >= current;
                    }}
                    placeholder={translate('forms.placeholders.chooseDate')}
                />
            </Form.Item>
            <Form.Item>
                <Row justify={'space-between'}>
                    <Button
                        htmlType="button"
                        type="primary"
                        ghost
                        fontWeight={500}
                        onClick={goBack}
                    >
                        {translate('forms.buttons.changeClient')}
                    </Button>
                    <Button
                        htmlType="submit"
                        type="primary"
                        fontWeight={500}
                        onClick={onSubmit}
                        disabled={isValid()}
                    >
                        {translate('forms.buttons.continue')}
                    </Button>
                </Row>
            </Form.Item>
        </Form>
    );
});
