import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios from '@axios';
import { RootState } from '@redux/reducers';
import { OrderRequest, OrderRequestApi } from '@models/order/items/request';
import { DraftResponseApi } from '@models/order/drafs/response';
import { toQueryParameters } from '@extensions/object';
import {
    ItemResponse,
    OrderResponse,
    PaginatedResponseApi,
} from '@models/order/items/response';
import { OrderResponseApi } from '@models/order/response';
import { ThunkCallback } from '../model/thunk-callback';
import { updateOrderState } from '.';
import { addNotification } from '../notification';
import { translate } from '@components/i18n';

export interface OrdersToBonificationRequest {
    customerId: number;
    platformId: number;
}

export interface OrderBonificationTable {
    key: string;
    name: string;
    code: string;
    order: string;
    total: number;
}

interface OrderBonificationProps {
    bonificationId?: number;
}

export const saveBonificationOrder = createAsyncThunk<
    void,
    ThunkCallback<OrderBonificationProps>
>('select-items/saveOrder', async (req, thunkAPI) => {
    try {
        const { bonification, selectItems } = thunkAPI.getState() as RootState;
        const { availableItems, request, order, draftRef } = selectItems;

        const requestApi = new OrderRequestApi(
            order,
            availableItems.filter((i) => i.quantity > 0),
            request,
            [
                ...bonification.selectedOrders
                    .filter((c) => c.key)
                    .map((c) => Number(c.key)),
                Number(draftRef),
            ],
            true,
        );

        if (!req.data.bonificationId) {
            const response = await axios.post<OrderResponse>(
                '/pedido',
                requestApi,
            );

            if (response.status === 201) {
                thunkAPI.dispatch(
                    addNotification({
                        type: 'success',
                        message: translate('general.bonificationOrderSuccess'),
                        title: translate('general.success'),
                        notificationKey: req.notificationKey,
                        callback: req.onSuccess,
                    }),
                );

                // thunkAPI.dispatch(
                //     sendOrder({
                //         notificationKey: req.notificationKey,
                //         data: [response.data.pedidoId],
                //     }),
                // );
            }
        } else {
            const response = await axios.put<OrderResponse>(
                '/pedido/' + req.data.bonificationId,
                requestApi,
            );

            if (response.status === 200) {
                thunkAPI.dispatch(
                    addNotification({
                        type: 'success',
                        message: translate('general.bonificationOrderUpdated'),
                        title: translate('general.success'),
                        notificationKey: req.notificationKey,
                        callback: req.onSuccess,
                    }),
                );

                // thunkAPI.dispatch(
                //     sendOrder({
                //         notificationKey: req.notificationKey,
                //         data: [response.data.pedidoId],
                //     }),
                // );
            }
        }
    } catch (e) {
        console.debug('erro', e);
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const fetchAvailableItemsBonification = createAsyncThunk<void, number>(
    'bonitifaction/fetchAvailableItemsBonification',
    async (req, thunkAPI) => {
        try {
            const response = await axios.get<OrderResponseApi>(
                '/pedido/' + req,
            );
            const result = Object.assign(new OrderResponseApi(), response.data);
            const { status, data } = response;
            if (status === 200) {
                thunkAPI.dispatch(updateOrderState(result.toOrderRequest()));
            } else {
                thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            //
        }
    },
);

export const setSelectedOrders = createAsyncThunk<
    Partial<OrderRequest>[],
    Partial<OrderRequest>[]
>('bonitifaction/setSelectedOrders', async (req, thunkAPI) => {
    try {
        const list: Partial<OrderRequest>[] = [];

        for (const order of req) {
            const response = await axios.get<OrderResponseApi>(
                '/pedido/' + order.key,
            );

            const result = Object.assign(new OrderResponseApi(), response.data);

            const { status, data } = response;

            if (status === 200) {
                list.push({
                    ...req,
                    ...result.toOrderRequest(),
                    key: result.id.toString(),
                    availableItems: result.toOrderRequest().tempItems,
                });
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        }
        return list;
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const fetchOrdersToBonitifaction = createAsyncThunk<
    Partial<OrderRequest>[],
    OrdersToBonificationRequest
>('bonitifaction/fetchOrdersToBonitifaction', async (req, thunkAPI) => {
    try {
        const requestP = toQueryParameters({
            codigoCliente: req.customerId,
        });

        const response = await axios.get<
            PaginatedResponseApi<DraftResponseApi>
        >('/pedido/list/naobonificados?' + requestP);

        const { status, data } = response;

        if (status === 200) {
            const result = response.data.content.map((u) =>
                Object.assign(new DraftResponseApi(), u),
            );

            return result.map((d) => d.toOrderState().order);
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export interface IBonificationState {
    request?: OrdersToBonificationRequest;
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage: string;
    availableOrders?: Partial<OrderRequest>[];
    selectedOrders?: Partial<OrderRequest>[];
    fetchingOrders: boolean;
    isSuccessFetchingOrders: boolean;
    tempItems?: Partial<ItemResponse>[];
}

const initialState: IBonificationState = {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    availableOrders: [],
    selectedOrders: [],
    tempItems: [],
    fetchingOrders: false,
    isSuccessFetchingOrders: false,
};

const selectTypeSlice = createSlice({
    name: 'selectTypeSlice',
    initialState,
    reducers: {
        setRequest: (
            state,
            action: PayloadAction<OrdersToBonificationRequest>,
        ) => {
            state.request = action.payload;
        },
        addOrderToBonification: (
            state,
            action: PayloadAction<Partial<OrderRequest>>,
        ) => {
            state.selectedOrders = [action.payload, ...state.selectedOrders];
            return state;
        },
        clearTempItems: (state) => {
            state.tempItems = [];
            return state;
        },
        addTempItems: (
            state,
            action: PayloadAction<Partial<ItemResponse>[]>,
        ) => {
            state.tempItems = action.payload;
            return state;
        },
        resetBonification: (state) => {
            state = { ...initialState };
            return state;
        },
    },
    extraReducers: {
        [fetchOrdersToBonitifaction.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Partial<OrderRequest>[]>,
        ) => {
            state.availableOrders = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchOrdersToBonitifaction.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },
        [fetchOrdersToBonitifaction.pending.toString()]: (state) => {
            state.isFetching = true;
            state.isError = false;
        },

        [setSelectedOrders.pending.toString()]: (state) => {
            state.isSuccessFetchingOrders = true;
            state.isSuccessFetchingOrders = false;
        },
        [setSelectedOrders.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Partial<OrderRequest>[]>,
        ) => {
            state.selectedOrders = [...state.selectedOrders, ...payload];
            state.isSuccessFetchingOrders = false;
            state.isSuccessFetchingOrders = true;
        },
    },
});

export const bonificationSelector = (state: RootState): IBonificationState =>
    state.bonification;

export const bonificationTableSelector = (
    state: RootState,
): OrderBonificationTable[] =>
    state.bonification.availableOrders.map<OrderBonificationTable>((o) => ({
        key: o.key,
        name: o.customer.name,
        code: `${o.customer.code}`,
        order: o.key,
        total: o.valorPedido,
    }));

export const {
    setRequest,
    resetBonification,
    addOrderToBonification,
    addTempItems,
    clearTempItems,
} = selectTypeSlice.actions;

export default selectTypeSlice.reducer;
