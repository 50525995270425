export interface CampaignRequest {
    nome: string;
    dataInicio: string;
    dataFim: string;
    exclusivo: boolean;
    tipo: string;
    condicoes: Conditions[];
    resultados: Result[];
}

export class CampaignRequestAPI {
    constructor(data?: CampaignRequest) {
        if (data) {
            Object.assign(this, data);
        }
    }

    nome: string;
    dataInicio: string;
    dataFim: string;
    exclusivo: boolean;
    tipo: string;
    condicoes: Conditions[];
    resultados: Result[];
}

export interface Conditions {
    id?: number;
    campanhaId?: number;
    campo: string;
    operador: string;
    numeroItem: string;
    valor: string;
}

export interface Result {
    id?: number;
    campanhaId?: number;
    email: string;
    numeroItem: string;
    qtd: number;
    originalQtd: number;
    desconto: number;
}

export interface CampaignRequestOrder {
    paymentConditionId: string;
    date?: Date;
}
