import { RouterPath } from '@definitions/routes';
import { ApprovalOrders } from '@pages/approval-orders';
import { DetailsOrders } from '@pages/approval-orders/details';
import { OrdersHistory } from '@pages/order-history';
import { Bonification } from '@pages/order/bonification';
import { Drafts } from '@pages/order/drafts';
import { MarketingBonitication } from '@pages/order/marketing-bonification';
import { SelectCustomer } from '@pages/order/select-client';
import { SelectItems } from '@pages/order/select-items';
import { SelectType } from '@pages/order/select-type';
import { SendedOrders } from '@pages/order/sended-orders';
import { ScheduledOrders } from '@pages/scheduled-orders';

const routes: RouterPath[] = [
    {
        path: '/create-order/select-customer',
        auth: true,
        render: () => <SelectCustomer />,
        allowedRoles: ['APROVADOR', 'REPRESENTANTE'],
    },
    {
        path: '/create-order/select-type',
        auth: true,
        render: () => <SelectType />,
        allowedRoles: ['APROVADOR', 'REPRESENTANTE'],
    },
    {
        path: '/create-order/select-items',
        auth: true,
        render: () => <SelectItems />,
        allowedRoles: ['APROVADOR', 'REPRESENTANTE'],
    },
    {
        path: '/draft-orders',
        auth: true,
        render: () => <Drafts />,
        allowedRoles: ['APROVADOR', 'REPRESENTANTE'],
    },
    {
        path: '/send-orders',
        auth: true,
        render: () => <SendedOrders />,
        allowedRoles: ['APROVADOR', 'REPRESENTANTE'],
    },
    {
        path: '/order/bonification',
        auth: true,
        render: () => <Bonification />,
        allowedRoles: ['APROVADOR', 'REPRESENTANTE'],
    },
    {
        path: '/order/marketing-bonification',
        auth: true,
        render: () => <MarketingBonitication />,
        allowedRoles: ['APROVADOR', 'REPRESENTANTE'],
    },
    {
        path: '/scheduled-orders',
        auth: true,
        render: () => <ScheduledOrders />,
        allowedRoles: ['APROVADOR', 'REPRESENTANTE'],
    },
    {
        path: '/approval-orders',
        auth: true,
        render: () => <ApprovalOrders />,
        allowedRoles: ['APROVADOR'],
    },
    {
        path: '/approval-order/:id',
        auth: true,
        render: () => <DetailsOrders />,
        allowedRoles: ['APROVADOR', 'REPRESENTANTE'],
    },
    {
        path: '/order/history',
        auth: true,
        render: () => <OrdersHistory />,
        allowedRoles: ['APROVADOR', 'REPRESENTANTE'],
    },
];

export default routes;
