import {
    CheckOutlined,
    CloseOutlined,
    MessageOutlined,
} from '@ant-design/icons';
import { translate } from '@components/i18n';
import '@extensions/number';
import { ItemResponse } from '@models/order/items/response';
import { HistoryItem } from '@pages/order/components/item-history';
import { CampaignIndicator } from '@pages/order/components/styled';
import { Button, Dot } from '@styles/components/button';
import { colors } from '@utils/constants';
import { formatMoney } from '@utils/money';
import { Col, Row, Tooltip } from 'antd';
import { useContext } from 'react';
import { ModalContext } from 'src/contexts/modal-context';
import { Comments } from './components/comments';
import { ApprovalContext } from './context/context';

export const columns = [
    {
        title: translate('columns.code'),
        dataIndex: 'id',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.representer'),
        dataIndex: 'representative',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.orderType'),
        dataIndex: 'type',
        sorter: false,
        width: '10%',
    },
    {
        title: translate('columns.division'),
        dataIndex: 'division',
        sorter: false,
        width: '10%',
    },
    {
        title: translate('columns.pandingWith'),
        dataIndex: 'pendingWith',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.clientCode'),
        dataIndex: 'customerCode',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.clientName'),
        dataIndex: 'customerName',
        sorter: false,
        width: '20%',
    },
    {
        title: translate('columns.customerCity'),
        dataIndex: 'customerCity',
        sorter: false,
        width: '20%',
    },
    // {
    //     title: 'Data do Pedido',
    //     dataIndex: 'date',
    //     sorter: false,
    //     width: '10%',
    // },
    {
        title: translate('columns.hasBonification'),
        dataIndex: 'hasBonification',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.total'),
        dataIndex: 'total',
        sorter: false,
        width: '15%',
    },
];

export const filterTypes = [
    {
        name: 'PEDIDO',
        description: translate('columns.order'),
    },
    {
        name: 'VALOR_TOTAL',
        description: translate('columns.totalAmount'),
    },
    {
        name: 'DATA',
        description: translate('columns.date'),
    },
    {
        name: 'DIVISAO',
        description: translate('columns.division'),
    },
    {
        name: 'CODIGO_CLIENTE',
        description: translate('columns.clientCode'),
    },
    {
        name: 'NOME_CLIENTE',
        description: translate('columns.clientName'),
    },
    {
        name: 'VENDEDOR',
        description: translate('columns.seller'),
    },
    {
        name: 'TIPO_PEDIDO',
        description: translate('columns.orderType'),
    },
];

export const filterComparison = [
    {
        name: 'EQ',
        description: translate('comparison.equalTo'),
    },
    {
        name: 'NEQ',
        description: translate('comparison.diferentThen'),
    },
    {
        name: 'GT',
        description: translate('comparison.biggerThen'),
    },
    {
        name: 'LT',
        description: translate('comparison.lessThen'),
    },
    {
        name: 'CONTAINS',
        description: translate('comparison.contains'),
    },
    {
        name: 'NOT_CONTAINS',
        description: translate('comparison.noContains'),
    },
    {
        name: 'BEGINS_WITH',
        description: translate('comparison.startWith'),
    },
];

export const itemsColumns = [
    {
        title: translate('columns.item'),
        dataIndex: 'description',
        width: '22%',
        render: (text, record): JSX.Element => {
            const campaigns = useContext(ApprovalContext);
            const campaign = campaigns.campaigns?.find(
                (c) => c.id === record.campanhaId,
            );
            const color =
                colors[
                    campaigns.campaigns?.findIndex(
                        (c) => c.id === record.campanhaId,
                    )
                ];
            return (
                <Row wrap={false}>
                    {record.campanhaId && (
                        <Tooltip title={`${campaign?.nome}`}>
                            <CampaignIndicator
                                color={color}
                            ></CampaignIndicator>
                        </Tooltip>
                    )}
                    {text}
                </Row>
            );
        },
    },
    {
        title: translate('columns.code'),
        dataIndex: 'code',
        width: '10%',
    },
    {
        title: translate('columns.qtd'),
        dataIndex: 'quantity',
        width: '7%',
    },
    {
        title: translate('columns.presentationQtd'),
        dataIndex: 'quantityPresentation',
        width: '10%',
    },
    {
        title: translate('columns.amountQtd'),
        dataIndex: 'totalQuantity',
        width: '10%',
    },
    {
        title: translate('columns.price'),
        dataIndex: 'costModifiable',
        width: '12%',
        render: (text, record): JSX.Element => {
            const context = useContext(ApprovalContext);

            return (
                <Row
                    style={{
                        display: 'flex',
                        columnGap: '5px',
                        userSelect: 'none',
                    }}
                >
                    {formatMoney(text)}
                    <HistoryItem
                        itemNumber={record.number}
                        clientCode={context.order.customer.code}
                        platformId={context.order.orderType.platform.id}
                    />
                </Row>
            );
        },
    },
    {
        title: translate('columns.promo'),
        dataIndex: 'promotion',
        width: '6%',
        render: (text, record): JSX.Element => {
            return (
                <span>
                    {isNaN(text) ? text : `${Number(text).toFixed(2)}%`}
                </span>
            );
        },
    },
    {
        title: translate('columns.discount'),
        dataIndex: 'discount',
        width: '8%',
        render: (text, _): JSX.Element => {
            return <span>{Number(text).toFixed(2)}%</span>;
        },
    },
    {
        title: translate('columns.subTotal'),
        dataIndex: 'total',
        width: '10%',
        render: (text, record): JSX.Element => {
            return (
                <span
                    style={{
                        display: 'flex',
                        columnGap: '5px',
                        userSelect: 'none',
                    }}
                >
                    {formatMoney(text)}
                    {record.semaforo && <Dot background={record.semaforo} />}
                </span>
            );
        },
    },
    {
        title: translate('columns.actions'),
        dataIndex: 'total',
        width: '10%',
        render: (text, record): JSX.Element => {
            const modalContext = useContext(ModalContext);
            const { comments, addComment, readOnly, removeComment } =
                useContext(ApprovalContext);

            return (
                <Row wrap={false} gutter={5}>
                    <Col style={{ display: 'none' }}>
                        <Button
                            success
                            type="primary"
                            ghost
                            disabled={readOnly}
                            height={35}
                            width={35}
                        >
                            <CheckOutlined />
                        </Button>
                    </Col>
                    <Col style={{ display: 'none' }}>
                        <Button
                            danger
                            ghost
                            height={35}
                            width={35}
                            disabled={readOnly}
                        >
                            <CloseOutlined />
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            ghost
                            type="primary"
                            height={35}
                            width={35}
                            disabled={readOnly}
                            onClick={() =>
                                modalContext.setIsOpen(
                                    true,
                                    <Comments
                                        comments={comments?.filter(
                                            (c) =>
                                                `${c.itemNumber}` ===
                                                `${record.number}`,
                                        )}
                                        addComment={addComment}
                                        removeComment={removeComment}
                                        readOnly={readOnly}
                                        item={`${record.number}`}
                                        orderId={record.defaultKey}
                                    />,
                                    {
                                        title: `${translate(
                                            'general.commentOfItem',
                                        )} ${record.number}`,
                                        className: 'modal-comments',
                                    },
                                )
                            }
                        >
                            <MessageOutlined />
                        </Button>
                    </Col>
                </Row>
            );
        },
    },
];

export const bonificationItemsColumns = [
    {
        title: 'Item',
        dataIndex: 'description',
        width: '22%',
        render: (text, record): JSX.Element => {
            const campaigns = useContext(ApprovalContext);
            const campaign = campaigns.campaigns?.find(
                (c) => c.id === record.campanhaId,
            );
            const color =
                colors[
                    campaigns.campaigns?.findIndex(
                        (c) => c.id === record.campanhaId,
                    )
                ];
            return (
                <Row wrap={false}>
                    {record.campanhaId && (
                        <Tooltip title={`${campaign?.nome}`}>
                            <CampaignIndicator
                                color={color}
                            ></CampaignIndicator>
                        </Tooltip>
                    )}
                    {text}
                </Row>
            );
        },
    },
    {
        title: translate('columns.code'),
        dataIndex: 'code',
        width: '10%',
    },
    {
        title: translate('columns.qtd'),
        dataIndex: 'quantity',
        width: '7%',
    },
    {
        title: translate('columns.presentationQtd'),
        dataIndex: 'quantityPresentation',
        width: '10%',
    },
    {
        title: translate('columns.amountQtd'),
        dataIndex: 'totalQuantity',
        width: '10%',
    },
    {
        title: translate('columns.price'),
        dataIndex: 'costModifiable',
        width: '12%',
        render: (text, record): JSX.Element => {
            const context = useContext(ApprovalContext);

            return (
                <Row
                    style={{
                        display: 'flex',
                        columnGap: '5px',
                        userSelect: 'none',
                    }}
                >
                    {formatMoney(text)}
                    <HistoryItem
                        itemNumber={record.number}
                        clientCode={context.order.customer.code}
                        platformId={context.order.orderType.platform.id}
                    />
                </Row>
            );
        },
    },
    {
        title: translate('columns.promo'),
        dataIndex: 'promotion',
        width: '6%',
        render: (text, _): JSX.Element => {
            return (
                <span>
                    {isNaN(text) ? text : `${Number(text).toFixed(2)}%`}
                </span>
            );
        },
    },
    {
        title: translate('columns.discount'),
        dataIndex: 'discount',
        width: '8%',
        render: (text, _): JSX.Element => {
            return <span>{Number(text).toFixed(2)}%</span>;
        },
    },
    {
        title: translate('columns.subTotal'),
        dataIndex: 'total',
        width: '10%',
        render: (text, _): JSX.Element => {
            return (
                <span
                    style={{
                        display: 'flex',
                        columnGap: '5px',
                        userSelect: 'none',
                    }}
                >
                    {formatMoney(text)}
                </span>
            );
        },
    },
    {
        title: translate('columns.actions'),
        dataIndex: 'total',
        width: '10%',
        render: (_, record: ItemResponse): JSX.Element => {
            const modalContext = useContext(ModalContext);
            const { comments, addComment, readOnly, removeComment } =
                useContext(ApprovalContext);

            return (
                <Row wrap={false} gutter={5}>
                    <Col style={{ display: 'none' }}>
                        <Button
                            success
                            type="primary"
                            ghost
                            disabled={readOnly}
                            height={35}
                            width={35}
                        >
                            <CheckOutlined />
                        </Button>
                    </Col>
                    <Col style={{ display: 'none' }}>
                        <Button
                            danger
                            ghost
                            height={35}
                            width={35}
                            disabled={readOnly}
                        >
                            <CloseOutlined />
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            ghost
                            type="primary"
                            height={35}
                            width={35}
                            disabled={readOnly}
                            onClick={() =>
                                modalContext.setIsOpen(
                                    true,
                                    <Comments
                                        comments={comments?.filter(
                                            (c) =>
                                                `${c.itemNumber}` ===
                                                `${record.number}`,
                                        )}
                                        addComment={addComment}
                                        removeComment={removeComment}
                                        readOnly={readOnly}
                                        item={`${record.number}`}
                                        orderId={record.defaultKey}
                                    />,
                                    {
                                        title: `${translate(
                                            'general.commentOfItem',
                                        )} ${record.number}`,
                                        className: 'modal-comments',
                                    },
                                )
                            }
                        >
                            <MessageOutlined />
                        </Button>
                    </Col>
                </Row>
            );
        },
    },
];
