import {
    ApproverOrderResponse,
    ApproverResponse,
} from '@models/approval/response';
import { CampaignResponse } from '@models/campaign';
import { CustomerResponse } from '@models/customer';
import { Entity, EntityString } from '@models/entity';
import { OrderValues } from '@redux/functions/order';
import moment from 'moment';
import { OrderType } from '../order-type';
import { ItemResponse } from './response';

export interface ItemRequest {
    endDate?: Date;
    paymentConditionId?: string;
    limitado?: string;
    draft?: boolean;
    tempItems?: Partial<ItemResponse>[];
}

export interface OrderRequest extends OrderValues {
    order?: string;
    key?: string;
    paymentCondition?: EntityString;
    items?: ItemRequest[];
    deliveryDate?: Date;
    addCustomer?: boolean;
    addCustomerSelected?: CustomerResponse;
    sendCopy?: SendCopyRequest;
    customer: CustomerResponse;
    orderType: OrderType;
    userBonification?: ApproverResponse;
    date?: Date;
    division?: string;
    status?: string;
    approvers?: ApproverOrderResponse[];
    userName?: string;

    bonificationId?: number;
    availableItems?: Partial<ItemResponse>[];
    tempItems?: Partial<ItemResponse>[];
    bonificationItems?: Partial<ItemResponse>[];
    ponderedDiscount?: number;
    aprovacaoPendenteUsuario?: string;
    protocolo?: string;
    campanhaIds: number[];
}

export const defaultOrderRequest: OrderRequest = {
    customer: undefined,
    orderType: undefined,
    items: [],
    semaforoCustoPedido: 0,
    semaforoNomePedido: '',
    semaforoPedido: '',
    valorPedido: 0,
    valorPedidoIPI: 0,
    valorSuframa: 0,
    valorTrade: 0,
    campanhaIds: [],
};

export interface SendCopyRequest {
    email: string;
    note: string;
    envioProximoPedidoVenda: boolean;
}

export class OrderRequestApi {
    codigoCliente: number;
    codigoFilial: number;
    codigoPrazo: string;
    codigoTipoPedido: string;
    dataPedido: string;
    itens: Item[];
    itensBonificacao?: Item[];
    valorTotal: number;
    valorTotalComIpi: number;
    codigoClienteEntrega: number;
    dataEntrega: string;
    dataProgramada: string;
    emailCopia: string;
    comentario: string;
    ordemCompra: string;
    pedidosBonificados: number[];
    aprovadorMarketingLimite: number;
    aprovadorMarketingUsuarioId: number;
    semaforoCor: string;
    semaforoNome: string;
    valorSemPTAX?: number;
    somaTotalCusto?: number;
    campanhaIds?: number[];
    envioProximoPedidoVenda?: boolean;

    constructor(
        request: Partial<OrderRequest>,
        items: Partial<ItemResponse>[],
        itemRequest: ItemRequest,
        bonificationOrders: number[] = [],
        bonnification = false,
        campaigns: number[] = [],
        availableCampaignForOrder: CampaignResponse[] = [],
    ) {
        this.codigoCliente = request.customer.code;
        this.codigoFilial = Number(request.orderType.platform.id);
        this.codigoPrazo = itemRequest.paymentConditionId;
        this.codigoTipoPedido = request.orderType.type.id.toString();
        this.semaforoCor = request.semaforoPedido;
        this.semaforoNome = request.semaforoNomePedido;
        console.debug(campaigns);
        this.campanhaIds = campaigns
            ? campaigns.filter((c) => c !== null)
            : items.map((i) => i.campaign?.id).filter((c) => c !== null);
        this.dataProgramada = request.orderType.date
            ? moment(request.orderType.date).format('YYYY-MM-DD')
            : '';
        this.emailCopia = request.sendCopy?.email;
        this.comentario = request.sendCopy?.note;
        this.dataEntrega = request.deliveryDate
            ? moment(request.deliveryDate).format('YYYY-MM-DD')
            : undefined;
        this.ordemCompra = request.order;
        this.valorTotal = request.valorPedido;
        this.valorTotalComIpi = request.valorPedidoIPI;
        this.codigoClienteEntrega = request.addCustomerSelected?.code
            ? request.addCustomerSelected?.code
            : request.addCustomerSelected?.id;
        this.dataPedido = moment().format('YYYY-MM-DD');
        this.pedidosBonificados = bonificationOrders;
        this.aprovadorMarketingUsuarioId = request.userBonification?.approverId;
        this.aprovadorMarketingLimite = request.userBonification?.limit;
        this.valorSemPTAX = request.valorSemPTAX;
        this.somaTotalCusto = request.somaTotalCusto;
        this.envioProximoPedidoVenda =
            request.sendCopy?.envioProximoPedidoVenda;

        if (bonnification) {
            this.codigoTipoPedido = 'V1';
            const campaigns = items
                .map((i) => i.campaign?.id)
                .filter((c) => !!c);
            const campaignSet = new Set(campaigns);
            this.campanhaIds = campaignSet.size > 0 ? [...campaignSet] : [];
        }

        if (!bonnification)
            this.campanhaIds = this.campanhaIds
                ? this.campanhaIds.filter((c) =>
                      availableCampaignForOrder.some((cc) => cc.id === c),
                  )
                : [];

        this.itens = items.map<Item>((item) => {
            return {
                desconto: item.discount,
                descricaoItem: item.description,
                ipi: item.ipi,
                multiplo: item.quantityPresentation,
                quantidade: item.quantity,
                numeroItem: item.number,
                precoTotal: item.subtotal,
                precoUnitario: item.valor,
                promocoes: Number(item.promotion),
                quantidadeTotal: item.totalQuantity,
                valorIpi: Number(item.subtotalipi),
                valorSuframa: item.suframa,
                valorTrade: item.valorTrade,
                ordem: item.order,
                semaforoCor: item.semaforo,
                semaforoNome: item.semaforoNome,
                precoUnitarioSemDesconto: item.valorsemdesconto,
                precoUnitarioSemCampanha: item.valorsemdesconto,
                precoUnitarioSemCampanhaSemDesconto: item.valorsemdesconto,
                campanhaId: item.campaign?.id,
                codigoLinhaItem: item.codigoLinha,
                codigoTipoTransporte: item.codigoTipoTransporte,
            };
        });
    }
}

export interface Item {
    descricaoItem: string;
    multiplo: number;
    numeroItem: string;
    precoTotal: number;
    precoUnitario: number;
    quantidade: number;
    quantidadeTotal: number;
    desconto: number;
    ipi: boolean;
    promocoes: number;
    valorIpi: number;
    valorSuframa: number;
    valorTrade: number;
    ordem: string;
    semaforoCor: string;
    semaforoNome: string;
    precoUnitarioSemDesconto: number;

    precoUnitarioSemCampanha?: number;
    precoUnitarioSemCampanhaSemDesconto?: number;
    campanhaId?: number;
    codigoLinha?: number;
    codigoTipoTransporte?: string;
}
