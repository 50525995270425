import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, message, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { FormBuilder } from '@components/form';
import {
    customerSelector,
    fetchCustomerTypes,
    fetchCustomerTypesClassification,
    orderItemsSelector,
    orderTypeSelector,
} from '@redux/slices/order';
import { EditableField } from '@models/editable-field';
import { divisionValues } from '@redux/slices/divisions';
import {
    brazilianStates,
    campaignEditableFields,
    conditionsJsonModel,
    resultsJsonModel,
} from '@pages/campaign/constants';
import { Loading } from '@styles/components/loader';
import {
    campaignItemSelector,
    createCampaign,
    fetchCampaignById,
    removeCampaign,
    updateCampaign,
} from '@redux/slices/campaign';
import { CampaignResponse, CampaignResponseApi } from '@models/campaign';
import { CustomButton } from '@pages/delegation/styled';
import { PlusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { translate } from '@components/i18n';
import { ENABLE_PAYMENT_CONDITION } from '@config/env';

interface EditCampaignModalProps {
    campaign?: CampaignResponse;
}

export const EditCampaignModal: React.FC<EditCampaignModalProps> = ({
    campaign,
}) => {
    const dispatch = useDispatch();
    const [started, setStarted] = useState(false);
    const modalContext = useContext(ModalContext);
    const { allAvailablePlatforms } = useSelector(orderTypeSelector);
    const divisions = useSelector(divisionValues);
    const { availablePaymentConditions } = useSelector(orderItemsSelector);
    const { availableCustomersType, availableCustomersTypeClassification } =
        useSelector(customerSelector);
    const allItems = useSelector(campaignItemSelector);
    const [form] = Form.useForm<CampaignResponse>();
    const [loading, setLoading] = useState<boolean>(false);
    const [internalKey, setInternalKey] = useState<string>('');

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const conditionsFields = () => {
        return [
            {
                id: 'QUANTIDADE_ITEM',
                name: translate('columns.itemQtd'),
                options: [],
            },
            {
                id: 'CONDICAO_PAGAMENTO',
                name: translate('columns.paymentCondition'),
                options: availablePaymentConditions.map((c) => ({
                    id: c.id,
                    name: c.description,
                })),
                disabled: !ENABLE_PAYMENT_CONDITION,
            },
            {
                id: 'FILIAL',
                name: translate('columns.branch'),
                options: allAvailablePlatforms.map((c) => ({
                    id: c.id,
                    name: `${c.id} - ${c.description}`,
                })),
            },
            {
                id: 'DIVISAO',
                name: translate('columns.division'),
                options: divisions.map((c) => ({
                    id: c.id,
                    name: c.nome,
                })),
            },
            {
                id: 'CLASSIFICACAO_CLIENTE',
                name: translate('columns.clientClassification'),
                options: availableCustomersTypeClassification.map((c) => ({
                    id: c.tipo,
                    name: `${c.tipo}`,
                    rm: true,
                })),
            },
            {
                id: 'TIPO_CLIENTE',
                name: translate('columns.clientType'),
                options: availableCustomersType.map((c) => ({
                    id: Number(c.classificacao),
                    name: `${c.classificacao} - ${c.tipo}`,
                    rm: true,
                })),
            },
            {
                id: 'ESTADO_CLIENTE',
                name: translate('columns.stateOfClient'),
                options: brazilianStates.map((c) => ({
                    id: c,
                    name: c,
                })),
            },
        ];
    };

    const handleAddField = (): void => {
        setFieldState((prev): EditableField[] => {
            const refItem = prev.findIndex((i) => i.name === 'condicoes');
            const lastCondition = prev
                .filter((i) => i.name.includes('c.'))
                .sort(
                    (a, b) =>
                        Number(b.name.split('.')[2]) -
                        Number(a.name.split('.')[2]),
                )[0]
                ?.name?.split('.')[2];

            const indexFieldApprovers = Number(lastCondition) + 1 || 1;

            return [
                ...prev.slice(0, refItem),
                ...conditionsJsonModel({
                    indexFieldApprovers,
                    options: [],
                    handleRemove: handleRemoveField,
                    numberOptions: allItems,
                }),
                ...prev.slice(refItem, prev.length),
            ];
        });
    };

    const handleAddResults = (tipo?: string): void => {
        setFieldState((prev): EditableField[] => {
            const refItem = prev.findIndex((i) => i.name === 'resultados');
            const lastCondition = prev
                .filter(
                    (i) =>
                        i.name.includes('r.') &&
                        !i.name.includes('or.') &&
                        !i.name.includes('c.hr.'),
                )
                .sort(
                    (a, b) =>
                        Number(b.name.split('.')[2]) -
                        Number(a.name.split('.')[2]),
                )[0]
                ?.name?.split('.')[2];

            const indexFieldApprovers = Number(lastCondition) + 1 || 1;

            return [
                ...prev.slice(0, refItem),
                ...resultsJsonModel({
                    indexFieldApprovers,
                    handleRemove: handleRemoveResultsField,
                    numberOptions: allItems,
                    tipo:
                        typeof tipo === 'string'
                            ? tipo
                            : editCampaignState.tipo,
                }),
                ...prev.slice(refItem, prev.length),
            ];
        });
    };

    const handleRemoveField = (index: number): void => {
        setFieldState((prev): EditableField[] => {
            form.setFieldsValue({
                [`c.campo.${index}`]: undefined,
                [`c.operador.${index}`]: undefined,
                [`c.valor.${index}`]: undefined,
                [`c.numeroItem.${index}`]: undefined,
            });

            return prev.filter(
                (i) =>
                    !i.name.includes('c.') ||
                    !i.name.split('.')[2]?.includes(`${index}`),
            );
        });
    };

    const handleRemoveResultsField = (index: number): void => {
        setFieldState((prev): EditableField[] => {
            form.setFieldsValue({
                [`r.qtd.${index}`]: undefined,
                [`r.desconto.${index}`]: undefined,
                [`r.email.${index}`]: undefined,
                [`r.numeroItem.${index}`]: undefined,
            });

            return prev.filter(
                (i) =>
                    !i.name.startsWith('r.') ||
                    !i.name.split('.')[2]?.includes(`${index}`),
            );
        });
    };

    const [fieldState, setFieldState] = useState<EditableField[]>(
        campaignEditableFields({
            handleAddField,
            handleAddResults,
            group: campaign,
            divisionOptions: divisions.map((c) => ({
                id: c.id,
                name: c.nome,
            })),
        }),
    );

    useEffect(() => {
        if (!fieldState.some((e) => e.name.includes('campo')) && !campaign)
            handleAddField();
    }, [fieldState]);

    const startConditions = (approversList) => {
        const condicoes = {};
        approversList.condicoes.forEach((element, i) => {
            (condicoes[`c.campo.${i + 1}`] = element.campo),
                (condicoes[`c.numeroItem.${i + 1}`] = element.numeroItem),
                (condicoes[`c.operador.${i + 1}`] = element.operador),
                (condicoes[`c.valor.${i + 1}`] = element.valor.split(';'));
        });
        return condicoes;
    };

    const startResult = (approversList) => {
        const resultados = {};
        approversList.resultados.forEach((element, i) => {
            (resultados[`r.email.${i + 1}`] = element.email),
                (resultados[`r.qtd.${i + 1}`] = element.qtd),
                (resultados[`r.desconto.${i + 1}`] = element.desconto),
                (resultados[`r.numeroItem.${i + 1}`] = element.numeroItem);
        });
        return resultados;
    };

    const [editCampaignState, setEditCampaignState] =
        useState<CampaignResponse>({
            ...campaign,
        });

    const onOk = (values: Partial<CampaignResponse>): void => {
        console.debug(values);
        const refactorValues = {
            nome: values.nome,
            dataInicio: moment(values.dataInicio)
                .add(3, 'hours')
                .format('YYYY-MM-DD'),
            dataFim: moment(values.dataFim)
                .add(3, 'hours')
                .format('YYYY-MM-DD'),
            exclusivo: values.exclusivo,
            finalizado: values.finalizado,
            tipo: values.tipo,
            divisao: values.divisao.toString().replace('undefined', ''),
            condicoes: Object.keys(values)
                .filter((item) => item.includes('c.'))
                .map((item) => {
                    if (item.includes('c.')) {
                        const index = item.split('.')[2];
                        return {
                            campo: values['c.campo.' + index],
                            numeroItem: values['c.numeroItem.' + index],
                            operador:
                                values['c.campo.' + index] === 'QUANTIDADE_ITEM'
                                    ? null
                                    : values['c.operador.' + index],
                            valor:
                                values['c.campo.' + index] === 'QUANTIDADE_ITEM'
                                    ? `${values['c.valor.' + index]}`
                                    : (values['c.valor.' + index] as [])
                                          .join(';')
                                          .replaceAll('undefined', ''),
                        };
                    }

                    return;
                }),
            resultados: Object.keys(values)
                .filter(
                    (item) =>
                        item.includes('r.') &&
                        !item.includes('or.') &&
                        !item.includes('c.hr.'),
                )
                .map((item) => {
                    if (
                        item.includes('r.') &&
                        !item.includes('or.') &&
                        !item.includes('c.hr.')
                    ) {
                        const index = item.split('.')[2];
                        return {
                            email: values['r.email.' + index],
                            numeroItem: values['r.numeroItem.' + index],
                            qtd: values['r.qtd.' + index],
                            desconto: values['r.desconto.' + index],
                        };
                    }

                    return;
                }),
        };

        refactorValues.condicoes = refactorValues.condicoes.filter(
            (value, index, array) => {
                const _value = JSON.stringify(value);
                return (
                    index ===
                    array.findIndex((obj) => {
                        return JSON.stringify(obj) === _value;
                    })
                );
            },
        );

        refactorValues.resultados = refactorValues.resultados.filter(
            (value, index, array) => {
                const _value = JSON.stringify(value);
                return (
                    index ===
                    array.findIndex((obj) => {
                        return JSON.stringify(obj) === _value;
                    })
                );
            },
        );

        console.debug(refactorValues, 'refactor values');

        if (!campaign) addNewGroup(refactorValues);
        else handleUpdateApprovalGroup(refactorValues);
    };

    const handleUpdateApprovalGroup = (values): void => {
        const orderKey = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.updatingCampain'),
            key: orderKey,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            updateCampaign({
                data: {
                    id: campaign.id,
                    ...values,
                },
                notificationKey: orderKey,
                onSuccess: () => {
                    modalContext.handleClose();
                },
            }),
        );
    };

    const addNewGroup = (values): void => {
        const key = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.registeringNewCampain'),
            key: key,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            createCampaign({
                data: {
                    ...values,
                },
                notificationKey: key,
                onSuccess: () => {
                    modalContext.handleClose();
                },
            }),
        );
    };

    useEffect(() => {
        if (campaign?.id) {
            (async () => {
                setLoading(true);
                const result = await fetchCampaignById(campaign.id);
                const data = Object.assign(new CampaignResponseApi(), result);

                setEditCampaignState({
                    ...campaign,
                    ...data.toCampaignResponse(),
                    dataInicio: data.dataInicio,
                    dataFim: data.dataFim,
                    ...startConditions(data.toCampaignResponse()),
                    ...startResult(data.toCampaignResponse()),
                });

                data.condicoes.forEach(() => handleAddField());
                data.resultados.forEach(() => handleAddResults(data.tipo));
                dispatch(fetchCustomerTypes({ divisao: data.divisao }));
                dispatch(
                    fetchCustomerTypesClassification({ divisao: data.divisao }),
                );

                setLoading(false);
                setStarted(true);
            })();
        } else {
            setStarted(true);
            setInternalKey(`${new Date().valueOf()}`);
        }
    }, [campaign]);

    useEffect(() => {
        if (started && campaign?.id) {
            onStart(editCampaignState);
        }
    }, [started]);

    const onStart = (values: any): void => {
        let fieldsStateClone = [...fieldState];
        for (const obj of Object.entries(values)) {
            if (obj[0].includes('c.campo.')) {
                const index = obj[0].split('.')[2];
                const defaultValueItem = fieldsStateClone.find(
                    (c) => c.name === `c.valor.${index}`,
                );

                const defaultItem = fieldsStateClone.find(
                    (c) => c.name === `c.numeroItem.${index}`,
                );

                const defaultItemOperator = fieldsStateClone.find(
                    (c) => c.name === `c.operador.${index}`,
                );

                const defaultItemValue = fieldsStateClone.find(
                    (c) => c.name === `c.valor.${index}`,
                );

                if (obj[1] !== 'QUANTIDADE_ITEM') {
                    defaultItem.disabled = true;
                    defaultItemOperator.disabled = false;
                    form.setFieldsValue({ [defaultItem.name]: '' });

                    defaultItemValue.multiple = true;
                    defaultItemValue.type = 'select';
                    defaultItem.rules = [];
                } else {
                    defaultItem.disabled = false;
                    defaultItemOperator.disabled = true;
                    form.setFieldsValue({ [defaultItemOperator.name]: '' });
                    defaultItemValue.multiple = false;
                    defaultItemValue.type = 'number';
                    defaultItem.rules = [
                        {
                            required: true,
                            message: translate('forms.rules.numberItem'),
                        },
                    ];
                }

                const options = conditionsFields().find((e) => e.id === obj[1]);

                defaultValueItem.options = options.options;

                const updatedFieldState = fieldsStateClone.map((item) => {
                    if (item.name === defaultItem.name) return defaultItem;
                    if (item.name === defaultValueItem.name)
                        return defaultValueItem;
                    return item;
                });

                fieldsStateClone = [...updatedFieldState];

                form.setFieldsValue({
                    [defaultValueItem.name]: editCampaignState[
                        defaultValueItem.name
                    ].map((c) => (isNaN(c) ? c : parseInt(c))),
                });
            } else if (obj[0].includes('tipo')) {
                const updatedFieldState = fieldsStateClone.map((i) => {
                    if (i.name === 'resultados') {
                        i = {
                            name: 'resultados',
                            label: translate('forms.labels.results'),
                            type: 'component',
                            span: 24,
                            Render: () => (
                                <CustomButton>
                                    <Button
                                        onClick={() =>
                                            handleAddResults(values.tipo)
                                        }
                                        className="border-none"
                                    >
                                        <PlusCircleOutlined />
                                        <span className="ml-2">
                                            {translate('forms.buttons.add')}
                                        </span>
                                    </Button>
                                </CustomButton>
                            ),
                        };
                        return i;
                    }

                    if (
                        values.tipo === 'NOTIFICACAO' &&
                        (i.name.includes('r.numeroItem') ||
                            i.name.includes('r.qtd') ||
                            i.name.includes('r.desconto'))
                    )
                        i.disabled = true;

                    if (
                        values.tipo !== 'NOTIFICACAO' &&
                        i.name.includes('r.email')
                    )
                        i.disabled = true;
                    return i;
                });

                fieldsStateClone = [...updatedFieldState];

                form.setFieldsValue({
                    tipo: values.tipo,
                });
            }
        }

        setFieldState(fieldsStateClone);
        setInternalKey(`${new Date().valueOf()}`);
    };

    useEffect(() => {
        if (editCampaignState.divisao) {
            const updatedFieldState = fieldState.map((item) => {
                if (
                    item.name.includes('c.valor.') &&
                    item.options.some((c) => c.rm)
                ) {
                    item.options = availableCustomersType.map((c) => ({
                        id: Number(c.classificacao),
                        name: `${c.classificacao} - ${c.tipo}`,
                        rm: true,
                    }));
                    item.defaultValue = undefined;
                }
                return item;
            });
            setFieldState(updatedFieldState);
        }
    }, [availableCustomersType]);

    const onChangeValues = (
        changedValues: Partial<CampaignResponse>,
        allValues: CampaignResponse,
    ): void => {
        setEditCampaignState({
            ...editCampaignState,
            ...allValues,
            ...changedValues,
        });

        if (changedValues.divisao) {
            dispatch(fetchCustomerTypes({ divisao: changedValues.divisao }));
            dispatch(
                fetchCustomerTypesClassification({
                    divisao: changedValues.divisao,
                }),
            );
        }

        if (changedValues.finalizado !== undefined) {
            return;
        }

        if (Object.keys(changedValues).some((c) => c.includes('c.campo.'))) {
            const index = Object.keys(changedValues)[0].split('.')[2];
            const defaultValueItem = fieldState.find(
                (c) => c.name === `c.valor.${index}`,
            );

            const defaultItem = fieldState.find(
                (c) => c.name === `c.numeroItem.${index}`,
            );

            const defaultItemOperator = fieldState.find(
                (c) => c.name === `c.operador.${index}`,
            );

            const defaultItemValue = fieldState.find(
                (c) => c.name === `c.valor.${index}`,
            );

            if (Object.values(changedValues)[0] !== 'QUANTIDADE_ITEM') {
                defaultItem.disabled = true;
                defaultItemOperator.disabled = false;
                form.setFieldsValue({ [defaultItem.name]: '' });
                defaultItem.rules = [];
                defaultItemValue.multiple = true;
                defaultItemValue.type = 'select';
            } else {
                defaultItem.disabled = false;
                defaultItem.rules = [
                    {
                        required: true,
                        message: translate('forms.rules.numberItem'),
                    },
                ];
                defaultItemOperator.disabled = true;
                form.setFieldsValue({ [defaultItemOperator.name]: '' });
                defaultItemValue.multiple = false;
                defaultItemValue.type = 'number';
            }

            const options = conditionsFields().find(
                (e) => e.id === Object.values(changedValues)[0],
            );

            defaultValueItem.options = options.options;

            form.setFieldsValue({ [defaultValueItem.name]: undefined });

            const updatedFieldState = fieldState.map((item) => {
                if (item.name === defaultItem.name) return defaultItem;
                if (item.name === defaultValueItem.name)
                    return defaultValueItem;
                return item;
            });

            setFieldState(updatedFieldState);
        } else if (changedValues.tipo) {
            if (!fieldState.some((e) => e.name.includes('qtd')) && !campaign) {
                const updatedFieldState = fieldState.map((i) => {
                    if (i.name === 'resultados')
                        i = {
                            name: 'resultados',
                            label: translate('forms.labels.results'),
                            type: 'component',
                            span: 24,
                            Render: () => (
                                <CustomButton>
                                    <Button
                                        onClick={() =>
                                            handleAddResults(changedValues.tipo)
                                        }
                                        className="border-none"
                                    >
                                        <PlusCircleOutlined />
                                        <span className="ml-2">
                                            {translate('forms.buttons.add')}
                                        </span>
                                    </Button>
                                </CustomButton>
                            ),
                        };
                    return i;
                });
                setFieldState(updatedFieldState);

                return handleAddResults(changedValues.tipo);
            }
            const defaultValueItem = editCampaignState.tipo;
            if (
                (defaultValueItem === 'DESCONTO' &&
                    changedValues.tipo === 'BONIFICACAO') ||
                (defaultValueItem === 'BONIFICACAO' &&
                    changedValues.tipo === 'DESCONTO')
            )
                return;

            const updatedFieldState = fieldState
                .filter((item) => {
                    return (
                        !item.name.includes('r.qtd') &&
                        !item.name.includes('r.desconto') &&
                        !item.name.includes('r.numero') &&
                        !item.name.includes('r.hr') &&
                        !item.name.includes('r.email')
                    );
                })
                .map((i) => {
                    if (i.name === 'resultados')
                        i = {
                            name: 'resultados',
                            label: translate('forms.labels.results'),
                            type: 'component',
                            span: 24,
                            Render: () => (
                                <CustomButton>
                                    <Button
                                        onClick={() =>
                                            handleAddResults(changedValues.tipo)
                                        }
                                        className="border-none"
                                    >
                                        <PlusCircleOutlined />
                                        <span className="ml-2">
                                            {translate('forms.buttons.add')}
                                        </span>
                                    </Button>
                                </CustomButton>
                            ),
                        };
                    return i;
                });

            setFieldState(updatedFieldState);

            return handleAddResults(changedValues.tipo);
        }
    };

    const handleDeleteApprovalGroup = (): void => {
        dispatch(removeCampaign(campaign.id));

        modalContext.handleClose();
    };

    if (loading || !started || !internalKey) return <Loading />;

    return (
        !loading && (
            <Row align="middle">
                <Col span={1} />
                <Col span={22}>
                    <FormBuilder
                        internalKey={internalKey}
                        onValuesChange={onChangeValues}
                        layout="vertical"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 24 }}
                        onlyEditable={
                            editCampaignState?.countPedidoRelacionado > 0
                                ? ['finalizado']
                                : undefined
                        }
                        initialValues={{
                            ...editCampaignState,
                            dataInicio: editCampaignState?.dataInicio
                                ? moment(editCampaignState?.dataInicio)
                                : undefined,
                            dataFim: editCampaignState?.dataFim
                                ? moment(editCampaignState?.dataFim)
                                : undefined,
                        }}
                        onFinish={onOk}
                        form={form}
                        fields={fieldState}
                        withDelete={
                            editCampaignState?.countPedidoRelacionado === 0 &&
                            !!campaign
                        }
                        onDelete={handleDeleteApprovalGroup}
                    />
                </Col>
                <Col span={1} />
            </Row>
        )
    );
};
