import React, { useEffect, useState } from 'react';
import { Page } from '@components/page';
import { LeftOutlined } from '@ant-design/icons';
import { SelectItemsHeader } from './components/select-items-header';
import { SelectItemsBody } from './components/select-items-body';
import { SelectItemsActions } from './components/select-items-actions';
import { UserBonification } from './components/user-bonification-modal';
import {
    customerSelector,
    draftsSelector,
    fetchAvailableItems,
    fetchAvailablePaymentConditions,
    fetchConvenio,
    fetchCustos,
    fetchPaymentCondition,
    fetchPdiscqt,
    fetchSemaphores,
    orderItemsSelector,
    orderTypeSelector,
    resetBonification,
    updateOrder,
    updateOrderState,
} from '@redux/slices/order';
import { useQuery } from '@hooks/use-query';
import { useDispatch, useSelector } from 'react-redux';
import { scheduledSelector } from '@redux/slices/order/scheduled';
import axios from '@axios';
import { OrderResponseApi } from '@models/order/response';
import { approvalMarketingSelector } from '@redux/slices/approval-marketing';
import { ApproverResponseApi } from '@models/approval/response';
import { OrderContextProvider } from './context/provider';
import { clearMinOrderState } from '@redux/slices/min-order';
import { translate } from '@components/i18n';
import { AppDispatch } from '@redux/store';
import { message } from 'antd';

export const MarketingBonitication: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const query = useQuery();
    const dispatch = useDispatch<AppDispatch>();
    const { customer } = useSelector(customerSelector);
    const { orderType } = useSelector(orderTypeSelector);
    const { drafts } = useSelector(draftsSelector);
    const { scheduled } = useSelector(scheduledSelector);
    const { availableApprovalMarketing } = useSelector(
        approvalMarketingSelector,
    );
    const { semaphores, custos, convenios, pdiscqt, order, paymentCondition,...selectItemsState } =
        useSelector(orderItemsSelector);


    const fetchPreData = async (): Promise<void> => {
        await Promise.all([
            dispatch(fetchCustos()).unwrap(),
            dispatch(fetchConvenio()).unwrap(),
            dispatch(fetchPdiscqt()).unwrap(),
            dispatch(fetchSemaphores()).unwrap(),
            dispatch(fetchPaymentCondition({data: order.customer.code})).unwrap(),
            dispatch(fetchAvailablePaymentConditions()).unwrap(),
            dispatch(resetBonification()),
            dispatch(clearMinOrderState()),
        ])
    }

    useEffect(() => {
        if(order?.customer?.code){
            fetchPreData().then(res => {
                getItems()
            })
        }
    }, [order?.customer?.code])

    const getItems = async (): Promise<void> => {
        const isDraft = query.get('draft');

        if (
            paymentCondition && paymentCondition.id && 
            selectItemsState.availablePaymentConditions.length > 0 &&
            !isDraft
        ) {
            
            if (!selectItemsState.draftRef){
                dispatch(
                    fetchAvailableItems({
                        paymentConditionId: `${paymentCondition.id}`,
                    }),
                );
            }
        } else if (
            paymentCondition && paymentCondition.id &&
            !selectItemsState.request.paymentConditionId &&
            !isDraft
        ) {
            message.info({
                content: translate('general.suggestionNotFound'),
                key: 'suggestion-not-found',
            });
            dispatch(
                fetchAvailableItems({
                    paymentConditionId: `${paymentCondition.id}`,
                }),
            );
        }
    }

    useEffect(() => {
        

        const isDraft = query.get('draft');
        const scheduledOrder = query.get('scheduled');

        if (isDraft) {
            const ref = query.get('ref');
            const order = scheduledOrder
                ? scheduled.find((d) => `${d.draftRef}` === ref)
                : drafts.find((d) => `${d.draftRef}` === ref);

            axios
                .get<OrderResponseApi>('/pedido/' + ref)
                .then((r) => {
                    const result = Object.assign(
                        new OrderResponseApi(),
                        r.data,
                    );

                    const bonificationUser = Object.assign(
                        new ApproverResponseApi(),
                        availableApprovalMarketing.find(
                            (w) =>
                                `${w.aprovadorId}` ===
                                `${result.aprovadorMarketingUsuarioId}`,
                        ),
                    );

                    console.debug(result);
                    console.debug({
                        ...order.order,
                        ...result.toOrderRequest(),
                        userBonification:
                            bonificationUser?.toApproverResponse(),
                        tempItems: result.toOrderRequest().tempItems,
                    });

                    dispatch(
                        updateOrderState({
                            ...order,
                            order: {
                                ...order.order,
                                ...result.toOrderRequest(),
                                userBonification:
                                    bonificationUser?.toApproverResponse(),
                                tempItems: result.toOrderRequest().tempItems,
                            },
                        }),
                    );
                    setLoading(false);
                })
                .catch(console.error);
        } else {
            dispatch(updateOrder({ customer, orderType }));
            setLoading(false);
        }
    }, []);

    return (
        <OrderContextProvider>
            <Page
                title={translate('pages.newOrderBonificatoinMarketing')}
                greyBackground
                onClickGoBack
                menuIcon={<LeftOutlined />}
                loading={loading}
            >
                <UserBonification />
                <SelectItemsHeader readOnly marketing />
                <SelectItemsBody bonification marketing />
                <SelectItemsActions bonification marketing />
            </Page>
        </OrderContextProvider>
    );
};
