/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { EditableField } from '@models/editable-field';
import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';

export const columns = [
    {
        title: translate('columns.code'),
        dataIndex: 'codigo',
        sorter: false,
        width: '10%',
    },
    {
        title: translate('columns.description'),
        dataIndex: 'descricao',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.division'),
        dataIndex: 'divisao',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.icmsCredit'),
        dataIndex: 'creditoIcms',
        sorter: true,
        width: '10%',
        render: (_, record): React.ReactNode => {
            return (
                <span>
                    {record?.creditoIcms
                        ? translate('general.yes')
                        : translate('general.no')}
                </span>
            );
        },
    },
    {
        title: translate('columns.minOrder'),
        dataIndex: 'pedidoMinimo',
        sorter: true,
        width: '15%',
        render: (_, record): React.ReactNode => {
            return <span>{formatMoney(record?.pedidoMinimo)}</span>;
        },
    },
];

export function editableFields(): EditableField[] {
    return [
        {
            name: 'codigo',
            label: translate('forms.labels.code'),
            type: 'text',
            span: 12,
        },
        {
            name: 'descricao',
            label: translate('forms.labels.description'),
            type: 'text',
            span: 12,
        },
        {
            name: 'divisao',
            label: translate('forms.labels.division'),
            type: 'text',
            span: 12,
        },
        {
            name: 'pedidoMinimo',
            label: translate('forms.labels.minOrder'),
            type: 'number',
            span: 12,
        },
        {
            name: 'creditoIcms',
            label: translate('forms.labels.icmsCredit'),
            type: 'boolean',
            span: 12,
        },
    ];
}
