import React, { memo } from 'react';
import { Col, Divider, Row } from 'antd';
import { SolidBackground } from '@styles/components/wrapper';
import { Title } from '@styles/components/text';
import { Dot } from '@styles/components/button';
import { OrderRequest } from '@models/order/items/request';
import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';

interface OrderDetailsTableFooterProps {
    order: Partial<OrderRequest>;
}

export const OrderDetailsTableFooter: React.FC<OrderDetailsTableFooterProps> =
    memo(({ order }) => {
        return (
            <SolidBackground isWhite paddingBottom={10}>
                {order && (
                    <Row style={{ width: '100%', marginTop: 10 }}>
                        <Divider type="horizontal" style={{ margin: '0px' }} />
                        <Row
                            justify="end"
                            style={{ width: '100%', marginTop: 10 }}
                            align="top"
                        >
                            <Title marginRight={'5px'} level={4} blue>
                                {translate('columns.orderAmount')}:
                            </Title>
                            <Title
                                blue
                                marginTop={'0px !important'}
                                marginRight={'40px'}
                                level={4}
                            >
                                <Row align="middle" gutter={10}>
                                    <Col>
                                        {order && (
                                            <>
                                                {formatMoney(
                                                    order.valorPedido ?? 0,
                                                )}
                                            </>
                                        )}
                                    </Col>
                                    <Col>
                                        {order?.orderType.type.id !== 'V2' && (
                                            <>
                                                {order.semaforoPedido && (
                                                    <Dot
                                                        background={
                                                            order.semaforoPedido
                                                        }
                                                    />
                                                )}
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            </Title>
                        </Row>
                    </Row>
                )}
            </SolidBackground>
        );
    });
