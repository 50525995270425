import { translate } from '@components/i18n';
import { SHOW_PROTOCOL } from '@config/env';
import { CommercialBonusSaleResponse } from '@models/report';
import { formatCurrency } from '@utils/money';
import moment from 'moment';
import Comparison from 'src/enums/comparison';

export const columns = [
    {
        title: translate('columns.bonusOrder'),
        dataIndex: 'bonusOrder',
        sorter: true,
        width: '10%',
    },
    SHOW_PROTOCOL && {
        title: translate('columns.bonusProtocol'),
        dataIndex: 'bonusProtocol',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.client'),
        dataIndex: 'customer',
        sorter: true,
        width: '5%',
    },
    {
        title: translate('columns.clientName'),
        dataIndex: 'customerName',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.orderDate'),
        dataIndex: 'date',
        sorter: true,
        width: '10%',
        render: (_, record: CommercialBonusSaleResponse): React.ReactNode => {
            return moment(record.date)
            .locale('pt-br')
            .format('DD/MM/YYYY HH:mm:ss')
        },
    },
    {
        title: translate('columns.bonusTotalAmount'),
        dataIndex: 'bonusTotalAmount',
        sorter: true,
        width: '10%',
        render: (_, record: CommercialBonusSaleResponse): React.ReactNode => {
            return formatCurrency(record.bonusTotalAmount || 0);
        },
    },
    {
        title: translate('columns.user'),
        dataIndex: 'user',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.division'),
        dataIndex: 'division',
        sorter: true,
        width: '5%',
    },
    {
        title: translate('columns.saleOrder'),
        dataIndex: 'saleOrder',
        sorter: true,
        width: '10%',
    },
    SHOW_PROTOCOL && {
        title: translate('columns.saleProtocol'),
        dataIndex: 'saleProtocol',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.saleTotalAmount'),
        dataIndex: 'saleTotalAmount',
        sorter: true,
        width: '10%',
        render: (_, record: CommercialBonusSaleResponse): React.ReactNode => {
            return formatCurrency(record.saleTotalAmount || 0);
        },
    },
    {
        title: translate('columns.status'),
        dataIndex: 'status',
        sorter: true,
        width: '10%',
        render: (_, record: CommercialBonusSaleResponse): React.ReactNode => (
            <span>{ translate(`columns.${record.status}`)}</span>
        ),
    },
];

export const filterTypes = [
    {
        name: 'pedido_bonificacao',
        description: translate('filters.bonusOrder'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    SHOW_PROTOCOL && {
        name: 'protocolo_bonificacao',
        description: translate('filters.bonusProtocol'),
        comparisonFilter: [
            Comparison.CONTAINS,
            Comparison.NOT_CONTAINS,
            Comparison.BEGINS_WITH,
            Comparison.EQ,
            Comparison.NEQ,
        ],
    },
    {
        name: 'codigo_cliente_bonificacao',
        description: translate('filters.client'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    {
        name: 'nome_cliente_bonificacao',
        description: translate('filters.clientName'),
        comparisonFilter: [
            Comparison.CONTAINS,
            Comparison.NOT_CONTAINS,
            Comparison.BEGINS_WITH,
            Comparison.EQ,
            Comparison.NEQ,
        ],
    },
    {
        name: 'data_bonificacao',
        description: translate('filters.orderDate'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    {
        name: 'valor_total_bonificacao',
        description: translate('filters.bonusTotalAmount'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    {
        name: 'vendedor_bonificacao',
        description: translate('filters.user'),
        comparisonFilter: [
            Comparison.CONTAINS,
            Comparison.NOT_CONTAINS,
            Comparison.BEGINS_WITH,
            Comparison.EQ,
            Comparison.NEQ,
        ],
    },
    {
        name: 'divisao_bonificacao',
        description: translate('filters.division'),
        comparisonFilter: [
            Comparison.CONTAINS,
            Comparison.NOT_CONTAINS,
            Comparison.BEGINS_WITH,
            Comparison.EQ,
            Comparison.NEQ,
        ],
    },
    {
        name: 'pedido_venda',
        description: translate('filters.saleOrder'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    SHOW_PROTOCOL && {
        name: 'protocolo_venda',
        description: translate('filters.saleProtocol'),
        comparisonFilter: [
            Comparison.CONTAINS,
            Comparison.NOT_CONTAINS,
            Comparison.BEGINS_WITH,
            Comparison.EQ,
            Comparison.NEQ,
        ],
    },
    {
        name: 'valor_total_venda',
        description: translate('filters.saleTotalAmount'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    {
        name: 'status_bonificacao',
        description: translate('filters.status'),
        comparisonFilter: [Comparison.EQ, Comparison.NEQ],
    },
];
