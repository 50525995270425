import moment from 'moment';
import { DelegationRequest, DelegationRequestAPI } from './request';

export interface DelegationResponse extends DelegationRequest {
    id: number;
    aprovadorId: number;
    dataInicio: string;
    dataTermino: string;
    delegacaoMotivo: string;
    delegadoId: number;
    descricao: string;
}

export class DelegationResponseApi implements DelegationRequestAPI {
    id: number;
    aprovadorId: number;
    dataInicio: string;
    dataTermino: string;
    delegacaoMotivo: string;
    delegadoId: number;
    descricao: string;

    toMarketingApprovalResponse(): DelegationResponse {
        return {
            id: this.id,
            aprovadorId: this.aprovadorId,
            dataInicio: moment(this.dataInicio, 'YYYY-MM-DD').toString(),
            dataTermino: moment(this.dataTermino, 'YYYY-MM-DD').toString(),
            delegacaoMotivo: this.delegacaoMotivo,
            delegadoId: this.delegadoId,
            descricao: this.descricao,
        };
    }
}
