import { translate } from '@components/i18n';
import { SHOW_PROTOCOL } from '@config/env';
import { ApprovedOrderResponse } from '@models/report';
import { formatCurrency } from '@utils/money';
import moment from 'moment';
import Comparison from 'src/enums/comparison';

export const columns = [
    {
        title: translate('columns.order'),
        dataIndex: 'order',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.orderType'),
        dataIndex: 'type',
        sorter: true,
        width: '10%',
    },
    SHOW_PROTOCOL && {
        title: translate('columns.protocol'),
        dataIndex: 'protocol',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.division'),
        dataIndex: 'division',
        sorter: true,
        width: '5%',
    },
    {
        title: translate('columns.client'),
        dataIndex: 'customer',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.clientName'),
        dataIndex: 'customerName',
        sorter: true,
        width: '15%',
    },
    {
        title: translate('columns.totalAmount'),
        dataIndex: 'totalAmount',
        sorter: true,
        width: '10%',
        render: (_, record: ApprovedOrderResponse): React.ReactNode => {
            return formatCurrency(record.totalAmount || 0);
        },
    },
    {
        title: translate('columns.deadline'),
        dataIndex: 'deadline',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.seller'),
        dataIndex: 'seller',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.date'),
        dataIndex: 'date',
        sorter: true,
        width: '10%',
        render: (_, record: ApprovedOrderResponse): React.ReactNode => {
            return moment(record.date)
            .locale('pt-br')
            .format('DD/MM/YYYY HH:mm:ss')
        },
    },
];

export const filterTypes = [
    {
        name: 'pedido',
        description: translate('filters.order'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    {
        name: 'codigo_cliente',
        description: translate('filters.client'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    {
        name: 'nome_cliente',
        description: translate('filters.clientName'),
        comparisonFilter: [
            Comparison.CONTAINS,
            Comparison.NOT_CONTAINS,
            Comparison.BEGINS_WITH,
            Comparison.EQ,
            Comparison.NEQ,
        ],
    },
    SHOW_PROTOCOL && {
        name: 'protocolo',
        description: translate('filters.protocol'),
        comparisonFilter: [
            Comparison.CONTAINS,
            Comparison.NOT_CONTAINS,
            Comparison.BEGINS_WITH,
            Comparison.EQ,
            Comparison.NEQ,
        ],
    },
    {
        name: 'divisao',
        description: translate('filters.division'),
        comparisonFilter: [
            Comparison.CONTAINS,
            Comparison.NOT_CONTAINS,
            Comparison.BEGINS_WITH,
            Comparison.EQ,
            Comparison.NEQ,
        ],
    },
    {
        name: 'valor_total',
        description: translate('filters.totalAmount'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    {
        name: 'tipo_pedido',
        description: translate('filters.orderType'),
        comparisonFilter: [Comparison.EQ, Comparison.NEQ],
    },
    {
        name: 'vendedor',
        description: translate('columns.seller'),
        comparisonFilter: [
            Comparison.CONTAINS,
            Comparison.NOT_CONTAINS,
            Comparison.BEGINS_WITH,
            Comparison.EQ,
            Comparison.NEQ,
        ],
    },
];
