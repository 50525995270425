/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { translate } from '@components/i18n';
import { EditableField } from '@models/editable-field';
import { delegationValues } from '@redux/slices/delegation';
import moment from 'moment';
import { useSelector } from 'react-redux';

const reasons = [
    {
        id: 'AUSENCIA_TEMPORARIA',
        name: translate('reasons.temporaryAbsent'),
    },
    {
        id: 'FERIAS',
        name: translate('reasons.vacation'),
    },
    {
        id: 'LICENCA_MATERNIDADE',
        name: translate('reasons.maternityLeave'),
    },
    {
        id: 'LICENCA_MEDICA',
        name: translate('reasons.medicalLeave'),
    },
    {
        id: 'OUTROS',
        name: translate('reasons.others'),
    },
    {
        id: 'VIAGEM',
        name: translate('reasons.travel'),
    },
];

export interface HeaderItem {
    title: string;
    dataIndex: string;
    sorter: boolean;
    width: string;
    render?: React.ReactNode;
}

export const approvalMarketingColumns = ({ optionsUser }): HeaderItem[] => {
    return [
        {
            title: translate('columns.approver'),
            dataIndex: 'aprovadorId',
            sorter: false,
            width: '20%',
            render: (_, record): React.ReactNode => {
                return (
                    <span>
                        {
                            optionsUser?.find(
                                (item) => item?.id === record?.aprovadorId,
                            )?.login
                        }
                    </span>
                );
            },
        },
        {
            title: translate('columns.delegate'),
            dataIndex: 'delegadoId',
            sorter: false,
            width: '20%',
            render: (_, record): React.ReactNode => {
                console.debug(optionsUser);
                return (
                    <span>
                        {
                            optionsUser?.find(
                                (item) => item.id === record.delegadoId,
                            )?.login
                        }
                    </span>
                );
            },
        },
        {
            title: translate('columns.startDate'),
            dataIndex: 'dataInicio',
            sorter: false,
            width: '20%',
            render: (_, record): React.ReactNode => {
                return (
                    <span>
                        {moment(record.dataInicio).format('DD/MM/YYYY')}
                    </span>
                );
            },
        },
        {
            title: translate('columns.endDate'),
            dataIndex: 'dataTermino',
            sorter: false,
            width: '20%',
            render: (_, record): React.ReactNode => {
                return (
                    <span>
                        {moment(record.dataTermino).format('DD/MM/YYYY')}
                    </span>
                );
            },
        },
        {
            title: translate('columns.reason'),
            dataIndex: 'delegacaoMotivo',
            sorter: false,
            width: '20%',
            render: (_, record): React.ReactNode => {
                return (
                    <span>
                        {
                            reasons.find(
                                (item) => item.id === record.delegacaoMotivo,
                            ).name
                        }
                    </span>
                );
            },
        },
    ];
};

export function approvalMarketingEditableFields({
    optionsUser,
    showDescription,
    editionId,
}): EditableField[] {
    const delegations = useSelector(delegationValues);
    const fields = [
        {
            name: 'aprovadorId',
            label: translate('columns.approver'),
            type: 'select',
            span: 24,
            options: optionsUser,
        },
        {
            name: 'delegadoId',
            label: translate('columns.delegate'),
            type: 'select',
            span: 24,
            options: optionsUser,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.delegate'),
                },
            ],
        },
        {
            name: 'dataInicio',
            label: translate('columns.startDate'),
            type: 'date',
            format: 'DD/MM/YYYY',
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.startDate'),
                },
                () => ({
                    validator(_, value) {
                        if (value && moment().isAfter(moment(value), 'day')) {
                            return Promise.reject(
                                new Error(
                                    translate(
                                        'forms.rules.startDateBeforeToday',
                                    ),
                                ),
                            );
                        }
                        return Promise.resolve();
                    },
                }),
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        const aprovador = getFieldValue('aprovadorId');
                        const ranges = delegations
                            .filter((d) => d.id !== editionId)
                            .filter(
                                (d) =>
                                    !aprovador ||
                                    `${aprovador}` === `${d.aprovadorId}`,
                            )
                            .map((d) => ({
                                begin: moment(d.dataInicio),
                                end: moment(d.dataTermino),
                                delegate: d.delegacaoMotivo,
                            }));

                        if (value) {
                            for (const range of ranges) {
                                const rangeOut = JSON.parse(
                                    JSON.stringify({ ...range }),
                                );

                                if (
                                    moment(value)
                                        // .startOf('day')
                                        .isBetween(
                                            moment(rangeOut.begin).startOf(
                                                'day',
                                            ),
                                            moment(rangeOut.end)
                                                .startOf('day')
                                                .add(23, 'hours'),
                                        )
                                ) {
                                    return Promise.reject(
                                        new Error(
                                            `${translate(
                                                'general.existDelegation',
                                            )} (${range.begin.format(
                                                'DD/MM/YY',
                                            )} - ${range.end
                                                // .add(-23, 'hours')
                                                .format('DD/MM/YY')} | ${
                                                range.delegate
                                            }) ${translate(
                                                'general.selectedPeriod',
                                            )}`,
                                        ),
                                    );
                                }
                            }
                        }

                        return Promise.resolve();
                    },
                }),
            ],
        },
        {
            name: 'dataTermino',
            label: translate('columns.endDate'),
            type: 'date',
            format: 'DD/MM/YYYY',
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.endDate'),
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (
                            value &&
                            moment(getFieldValue('dataInicio')).isAfter(
                                moment(value),
                                'day',
                            )
                        ) {
                            return Promise.reject(
                                new Error(
                                    translate(
                                        'forms.rules.endDateCannotBeBeforeStart',
                                    ),
                                ),
                            );
                        }
                        return Promise.resolve();
                    },
                }),
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        const aprovador = getFieldValue('aprovadorId');
                        const ranges = delegations
                            .filter((d) => d.id !== editionId)
                            .filter(
                                (d) =>
                                    !aprovador ||
                                    `${aprovador}` === `${d.aprovadorId}`,
                            )
                            .map((d) => ({
                                begin: moment(d.dataInicio),
                                end: moment(d.dataTermino),
                                delegate: d.delegacaoMotivo,
                            }));

                        if (value) {
                            for (const range of ranges) {
                                const rangeOut = JSON.parse(
                                    JSON.stringify({ ...range }),
                                );

                                if (
                                    moment(value)
                                        // .startOf('day')
                                        .isBetween(
                                            moment(rangeOut.begin).startOf(
                                                'day',
                                            ),
                                            moment(rangeOut.end)
                                                .startOf('day')
                                                .add(23, 'hours'),
                                        )
                                ) {
                                    return Promise.reject(
                                        new Error(
                                            `${translate(
                                                'general.existDelegation',
                                            )} (${range.begin.format(
                                                'DD/MM/YY',
                                            )} - ${range.end.format(
                                                'DD/MM/YY',
                                            )} | ${range.delegate}) ${translate(
                                                'general.selectedPeriod',
                                            )}`,
                                        ),
                                    );
                                }

                                const beginDate = getFieldValue('dataInicio')
                                    ? moment(getFieldValue('dataInicio'))
                                    : null;

                                if (beginDate) {
                                    if (
                                        moment(rangeOut.begin).isBetween(
                                            beginDate.startOf('day'),
                                            moment(value).endOf('day'),
                                        )
                                    ) {
                                        return Promise.reject(
                                            new Error(
                                                `${translate(
                                                    'general.existDelegation',
                                                )} (${range.begin.format(
                                                    'DD/MM/YY',
                                                )} - ${range.end.format(
                                                    'DD/MM/YY',
                                                )} | ${range.delegate}) ${
                                                    range.delegate
                                                }) ${translate(
                                                    'general.selectedPeriod',
                                                )}`,
                                            ),
                                        );
                                    }
                                }
                            }
                        }

                        return Promise.resolve();
                    },
                }),
            ],
        },
        {
            name: 'delegacaoMotivo',
            label: translate('columns.delegateReason'),
            type: 'select',
            span: 24,
            options: reasons,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.delegateReason'),
                },
            ],
        },
        {
            name: 'descricao',
            label: translate('columns.description'),
            type: 'text',
            span: (showDescription && 24) || 0,
        },
    ];

    return fields;
}
