export interface CommentResponse {
    id: number;
    comment: string;
    userId: number;
    userName: string;
    itemNumber: string;
    date: Date;
    reproved: boolean;
    orderId: number;
}

export class CommentResponseApi {
    id: number;
    usuarioId: number;
    usuarioNome: string;
    pedidoId: number;
    dataHora: string;
    numeroItem: string;
    texto: string;
    reprovacao: boolean;
    descontoFinanceiroId?: number;

    toCommentResponse(): CommentResponse {
        return {
            id: this.id,
            comment: this.texto,
            userId: this.usuarioId,
            userName: this.usuarioNome,
            itemNumber: this.numeroItem,
            date: new Date(this.dataHora),
            reproved: this.reprovacao,
            orderId: this.pedidoId ?? this.descontoFinanceiroId,
        };
    }
}
